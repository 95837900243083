import React from "react";
import {
    LayersControl,
    MapContainer,
    Marker,
    Popup,
    WMSTileLayer,
    Tooltip as MapToolTip,
  } from "react-leaflet";
  import _ from "lodash";
import  RotatedMarker  from "./rotatedMarker";
import CustomMapTooltip from "./CustomMapTooltip";
import  RotatedMarkerIcon  from './RotatedMarkerIcon'
  function CustomRotatedMarker  (props) {
    const {position,selectedAsset,fullscreenmode,item,index,FocusAsset} = props
       
          return (
            <RotatedMarker
            key={index}
            position={position}
            icon={ RotatedMarkerIcon(item) }
            rotationAngle={ FocusAsset ?  _.toNumber(item.angle) :
              item.idleViolationOn === 1
                ? 0
                : item.speedViolationOn === 1
                  ? 0
                  : _.toNumber(item.angle)
            }
            rotationOrigin="center"
            onClick={() => selectedAsset(item)}
                            onMouseOver={(e) => {
                              e.target.openPopup();
                            }}
                            onMouseOut={(e) => {
                              e.target.closePopup();
                            }}
  


          >
            <Popup>
              <CustomMapTooltip
                plateno={item.plateNo}
                issuedby={item.regEmirate}
                make={item.make}
                model={item.modelName}
                driver={item.driverName}
                phone={item.driverPhone}
                speed={item.speed}
                time={item.dateTime}
                temp1={item.tSensor1}
                temp2={item.tSensor2}
              />
            </Popup>
            {
                              fullscreenmode === 1 && 
                              <MapToolTip className="maptooltip"
                              direction="bottom" offset={[0, 0]} opacity={1} permanent>
                              {item.plateNo}
                            </MapToolTip>
                            }
                           

          </RotatedMarker>
          )
        


      
}
export default CustomRotatedMarker;