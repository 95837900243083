import AutoComplete from "./AutoComplete";
import CustomTextField from './TextField'
import CustomMultiSelectComponent from './MultiSelectComponent'

const Controls = {
    AutoComplete,
    CustomTextField,
    CustomMultiSelectComponent,
}

export default Controls;