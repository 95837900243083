/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as liveViewAction from "../../../infofleet/ducks/liveView";

import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab } from "react-bootstrap";
import { serverurl } from "../../router/config";
import { toAbsoluteUrl } from "../../../infofleet";
// import useSound from "use-sound";
// import sampleaudio from "../../../assets/infofleet/notification_audio.mp3";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

function NotoficationPanel({ liveView }) {
  var notifications = liveView.notificationlist;
  const dispatch = useDispatch();
  // const soundUrl = sampleaudio;

  // const [play, { stop }] = useSound(soundUrl, { volume: 0.5 });

  const [state, setState] = React.useState({
    selectedTab: "Idle",
  });
  const setTab = (_tabName) => {
    setState({ selectedTab: _tabName });
  };

  // Alert Select

  function alertselect(event) {
    dispatch(liveViewAction.actions.selectednotification(event));
  }
  // Alert Select

  const IdleAlert =
    notifications !== "" &&
    notifications !== undefined &&
    notifications !== null
      ? notifications.filter(function(obj) {
          return obj.alertName === "Excessive Idle Alert";
        })
      : "";
  const SpeedAlert =
    notifications !== "" &&
    notifications !== undefined &&
    notifications !== null
      ? notifications.filter(function(obj) {
          return obj.alertName === "Over Speed";
        })
      : "";
  const NormalAlert =
    notifications !== "" &&
    notifications !== undefined &&
    notifications !== null
      ? notifications.filter(function(obj) {
          return (
            obj.alertName !== "Excessive Idle Alert" &&
            obj.alertName !== "Over Speed"
          );
        })
      : "";

  // Reset Notification
  function resetnotification(event) {
    dispatch(liveViewAction.actions.selectednotification(""));
  }

  // Reset Notification

  return (
    <div id="kt_quick_panel" className="kt-quick-panel">
      <a
        href="#"
        className="kt-quick-panel__close"
        id="kt_quick_panel_close_btn"
        onClick={resetnotification}
      >
        <i className="flaticon2-delete" />
      </a>
      <Tab.Container defaultActiveKey={state.selectedTab}>
        <div className="kt-quick-panel__nav">
          <Nav
            onSelect={setTab}
            className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
          >
            <Nav.Item className="nav-item">
              <Nav.Link eventKey="Idle" className="nav-link show">
                Idle{" "}
                {IdleAlert.length !== 0 &&
                IdleAlert.length !== undefined &&
                IdleAlert.length !== null
                  ? "(" + `${IdleAlert.length}` + ")"
                  : ""}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link eventKey="Speed" className="nav-link show">
                Speed{" "}
                {SpeedAlert.length !== 0 &&
                SpeedAlert.length !== undefined &&
                SpeedAlert.length !== null
                  ? "(" + `${SpeedAlert.length}` + ")"
                  : ""}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link eventKey="Notifications" className="nav-link show">
                Notifications{" "}
                {NormalAlert.length !== 0 &&
                NormalAlert.length !== undefined &&
                NormalAlert.length !== null
                  ? "(" + `${NormalAlert.length}` + ")"
                  : ""}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="kt-quick-panel__content">
          <Tab.Content>
            <Tab.Pane eventKey="Idle">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  position: "relative",
                }}
              >
                {IdleAlert !== "" &&
                IdleAlert !== undefined &&
                IdleAlert !== null &&
                IdleAlert.length !== 0 ? (
                  <>
                    <div className="kt-notification">
                      {IdleAlert.map((item, index) => {
                        var icon = "yellow";

                        return (
                          <div
                            className="kt-notification__item"
                            onClick={() => alertselect(item)}
                          >
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/info_" +
                                      icon +
                                      ".png"
                                  )}
                                />
                                {item.alertName}
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/asseticon.png"
                                  )}
                                />
                                {item.plateNo} / {item.assetMake} /{" "}
                                {item.assetModel}
                              </div>
                              <div className="driver_section mb_5">
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/drivericon.png"
                                    )}
                                  />
                                  {item.driverName}
                                </div>
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/timeicon.png"
                                    )}
                                  />
                                  {item.dateTime}
                                </div>
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/locationicon.png"
                                  )}
                                />
                                {item.location}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>{" "}
                  </>
                ) : (
                  <div className="kt-notification-v2">
                    <div className="kt-notification-v2__item">
                      <div className="kt-notification-v2__item-icon">
                        <i className="flaticon-bell kt-font-brand" />
                      </div>
                      <div className="kt-notification-v2__itek-wrapper">
                        <div className="kt-notification-v2__item-title">
                          There is No unread notification.....
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </PerfectScrollbar>
            </Tab.Pane>
            <Tab.Pane eventKey="Speed">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  position: "relative",
                }}
              >
                {SpeedAlert !== "" &&
                SpeedAlert !== undefined &&
                SpeedAlert !== null &&
                SpeedAlert.length !== 0 ? (
                  <>
                    <div className="kt-notification">
                      {SpeedAlert.map((item, index) => {
                        var icon = "red";
                        return (
                          <div
                            className="kt-notification__item"
                            onClick={() => alertselect(item)}
                          >
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/info_" +
                                      icon +
                                      ".png"
                                  )}
                                />
                                {item.alertName}  ({item.speed})
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/asseticon.png"
                                  )}
                                />
                                {item.plateNo} / {item.assetMake} /{" "}
                                {item.assetModel}
                              </div>
                              <div className="driver_section mb_5">
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/drivericon.png"
                                    )}
                                  />
                                  {item.driverName}
                                </div>
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/timeicon.png"
                                    )}
                                  />
                                  {item.dateTime}
                                </div>
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/locationicon.png"
                                  )}
                                />
                                {item.location}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>{" "}
                  </>
                ) : (
                  <div className="kt-notification-v2">
                    <div className="kt-notification-v2__item">
                      <div className="kt-notification-v2__item-icon">
                        <i className="flaticon-bell kt-font-brand" />
                      </div>
                      <div className="kt-notification-v2__itek-wrapper">
                        <div className="kt-notification-v2__item-title">
                          There is No unread notification.....
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </PerfectScrollbar>
            </Tab.Pane>
            <Tab.Pane eventKey="Notifications">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  position: "relative",
                }}
              >
                {NormalAlert !== "" &&
                NormalAlert !== undefined &&
                NormalAlert !== null &&
                NormalAlert.length !== 0 ? (
                  <>
                    <div className="kt-notification">
                      {NormalAlert.map((item, index) => {
                        var icon =
                          item.alertName === "Over Speed" ||
                          item.alertName === "Over Speed ends"
                            ? "red"
                            : item.alertName === "Excessive Idle Alert" ||
                              item.alertName === "Ignition On" ||
                              item.alertName === "Ignition Off"
                            ? "yellow"
                            : "green";
                        return (
                          <div className="kt-notification__item">
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/info_" +
                                      icon +
                                      ".png"
                                  )}
                                />
                                {item.alertName}
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/asseticon.png"
                                  )}
                                />
                                {item.plateNo} / {item.assetMake} /{" "}
                                {item.assetModel}
                              </div>
                              <div className="driver_section mb_5">
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/drivericon.png"
                                    )}
                                  />
                                  {item.driverName}
                                </div>
                                <div>
                                  <img
                                    alt="Logo"
                                    src={toAbsoluteUrl(
                                      "/media/icons/Notification/timeicon.png"
                                    )}
                                  />
                                  {item.dateTime}
                                </div>
                              </div>
                              <div className="mb_5">
                                <img
                                  alt="Logo"
                                  src={toAbsoluteUrl(
                                    "/media/icons/Notification/locationicon.png"
                                  )}
                                />
                                {item.location}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>{" "}
                  </>
                ) : (
                  <div className="kt-notification-v2">
                    <div className="kt-notification-v2__item">
                      <div className="kt-notification-v2__item-icon">
                        <i className="flaticon-bell kt-font-brand" />
                      </div>
                      <div className="kt-notification-v2__itek-wrapper">
                        <div className="kt-notification-v2__item-title">
                          There is No unread notification.....
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </PerfectScrollbar>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}

const mapStateToProps = ({ liveView }) => ({ liveView });
export default connect(mapStateToProps, { liveViewAction })(NotoficationPanel);
