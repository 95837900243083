import React, { useEffect } from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField
} from "@material-ui/core";
import { Formik } from 'formik';
import axios from "axios";
import _ from "lodash";
import { toAbsoluteUrl } from '../../../infofleet/utils/utils'
import {
    CheckBoxSelection,
    Inject,
    MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
import { serverurl } from '../../router/config';

function Alertinfo(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Function for right click Add Geofence
function AddGeofencebody(props) {
    const { geofencestatelatlng, vehiclelist, actions, levels, handleReInitializeGeofence, geofenceRadius,handleLoaderView } = props;
    

    const Geofenceassetarray =
        vehiclelist !== "" && vehiclelist !== undefined
            ? vehiclelist.filter(function (obj) {
                return obj.type === "child";
            })
            : "";

    const [vehicleIds, VehicleIds] = React.useState();
    const [geofenceResponse, setGeofenceResponse] = React.useState("");
    const [geofenceopen, setGeofenceOpen] = React.useState(false);
    const [geofencestate, setGeofencestate] = React.useState({
        geofenceoption: "",
        // vehicleIds: "",
        geolevel: "",
        geoaction: "",
        radius: 400,
        enable: false,
        angleEntry: "90",
        angleExit: "90",
        gapEntry: "180",
        gapExit: "180",
        geofencelat: "",
        geofencelng: "",

        entryEnable: "false",
        exitEnable: "false",
    })

    const fields1 = { groupBy: "group", text: "name", value: "vehicleId" };

    const radius = (event) => {

        setGeofencestate({ ...geofencestate, radius: Math.round(event.target.value) });
        geofenceRadius(event)

    };

    const angleEntry = (event) => {

        setGeofencestate({ ...geofencestate, angleEntry: event.target.value });

    };
    const angleExit = (event) => {

        setGeofencestate({ ...geofencestate, angleExit: event.target.value });

    };
    const gapEntry = (event) => {

        setGeofencestate({ ...geofencestate, gapEntry: event.target.value });

    };
    const gapExit = (event) => {

        setGeofencestate({ ...geofencestate, gapExit: event.target.value });

    };

    // Asset Change
    function Geofenceassetchange(event) {

        VehicleIds(event.value);

    }
    // Asset Change

    // geofence Level Change
    function levelchange(event) {
        const val = event.target.value;

        setGeofencestate({ ...geofencestate, geolevel: val });

    }
    // geofence Level Change

    // geofence action Change
    function actionchange(event) {
        const val = event.target.value;

        setGeofencestate({ ...geofencestate, geoaction: val });

    }
    // geofence action Change

    const enablecheckbox = (event) => {
        setGeofencestate({ ...geofencestate, enable: event.target.checked });
    };
    const entryEnable = (event) => {

        setGeofencestate({ ...geofencestate, entryEnable: event.target.checked });

    };

    const exitEnable = (event) => {

        setGeofencestate({ ...geofencestate, exitEnable: event.target.checked });

    };

    const ReInitializeGeofence = () => {

        setGeofencestate({
            ...geofencestate,
            geofenceoption: "",
            geolevel: "",
            geoaction: "",
            radius: 400,
            enable: false,
            angleEntry: "90",
            angleExit: "90",
            gapEntry: "180",
            gapExit: "180",
            geofencelat: "",
            geofencelng: "",
            entryEnable: "false",
            exitEnable: "false"
        })
        handleReInitializeGeofence()
    };

    const handleGeofenceinfoClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setGeofenceOpen(false);
    };

    useEffect(() => {
        setGeofencestate({ ...geofencestate, geofencelat: geofencestatelatlng.geofencelat, geofencelng: geofencestatelatlng.geofencelng })
    }, [geofencestatelatlng])
    return (
        geofencestatelatlng.geofencelat !== "" && geofencestatelatlng.geofencelng !== "" ?
            <div className="GeofencePopup">
                <div className="togle_head">
                    Add Geofence{" "}
                    <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/icons/create_geo.svg")}
                    />
                </div>
                <div >
                    <div className=" col-md-12 p_0">

                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: "",
                                level: "",
                                vehicleid: "",
                                action: "",

                            }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.name) {
                                    errors.name = "Geofence Name is Required";
                                }
                                if (!vehicleIds) {
                                    errors.vehicleid = "VehicleId's Required";
                                }
                                if (!geofencestate.geolevel) {
                                    errors.level = "Geofence Level is Required";
                                }
                                if (!geofencestate.geolevel) {
                                    errors.action = "Geofence Action is Required";
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {

                                handleLoaderView("true");

                                setTimeout(() => {
                                    const requestBody = {
                                        angleEntry: geofencestate.angleEntry,
                                        angleExit: geofencestate.angleExit,
                                        entryEnable: _.toString(geofencestate.entryEnable),
                                        exitEnable: _.toString(geofencestate.exitEnable),
                                        gapEntry: geofencestate.gapEntry,
                                        gapExit: geofencestate.gapExit,
                                        geofenceActionId: _.toString(geofencestate.geoaction),
                                        geofenceId: "-1",
                                        geofenceLevelId: _.toString(geofencestate.geolevel),
                                        geofenceName: values.name,
                                        geofenceTypeId: "1",
                                        geom: "",
                                        geomText:
                                            _.toString(
                                                geofencestate.geofencelng +
                                                " " +
                                                geofencestate.geofencelat
                                            ),
                                        grl: "",
                                        radius: _.toString(geofencestate.radius),
                                        vehicleIds: _.toString(vehicleIds),
                                    };

                                    const config = {
                                        headers: {
                                            "Content-Type":
                                                "application/json; charset=utf-8",
                                        },
                                    };
                                    axios
                                        .post(
                                            serverurl + "UpdateGeofence",
                                            requestBody,
                                            config
                                        )
                                        .then(function (response) {
                                            handleLoaderView("false");
                                            const value = response.data;
                                            setGeofenceResponse(value);
                                            setGeofenceOpen(true);


                                        });
                                    setSubmitting(false);
                                }, 300);

                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (

                                <form onSubmit={handleSubmit}>
                                    <div className="AddGeofenceModal">
                                        <div className="mb_15">
                                            <TextField
                                                type="text"
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                                id="standard-basic"
                                                label="Geofence Name*"
                                                className="col-md-12"
                                            />
                                            <span className="error-msg">
                                                {errors.name && touched.name && errors.name}
                                            </span>
                                        </div>
                                        <div className="mb_15">
                                            <FormControl className="col-md-12 p_0">
                                                <InputLabel id="demo-simple-select-label">
                                                    Select a Level
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={geofencestate.geolevel}
                                                    onChange={levelchange}
                                                >
                                                    {levels !== undefined && levels !== ""
                                                        ? levels.map((item, index) => (
                                                            <MenuItem value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                        : ""}
                                                </Select>
                                            </FormControl>
                                            <span className="error-msg">
                                                {errors.level}
                                            </span>
                                        </div>

                                        <div className="mb_15">
                                            <MultiSelectComponent
                                                id="mtselement"
                                                popupHeight="300px"
                                                fields={fields1}
                                                dataSource={Geofenceassetarray}
                                                placeholder="Select Asset*"
                                                mode="CheckBox"
                                                enableGroupCheckBox={true}
                                                filterType="Contains"
                                                allowFiltering="true"
                                                change={Geofenceassetchange}
                                                showSelectAll="true"
                                                filterBarPlaceholder="Search Assets"
                                                value={vehicleIds}
                                            >
                                                <Inject services={[CheckBoxSelection]} />
                                            </MultiSelectComponent>
                                            <span className="error-msg">
                                                {
                                                    errors.vehicleid}
                                            </span>
                                        </div>
                                        <div className="mb_15">
                                            <FormControl className="col-md-12 p_0">
                                                <InputLabel id="demo-simple-select-label">
                                                    Select a Action
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={geofencestate.geoaction}
                                                    onChange={actionchange}
                                                >
                                                    {actions !== undefined && actions !== ""
                                                        ? actions.map((item, index) => (
                                                            <MenuItem value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))
                                                        : ""}
                                                </Select>
                                            </FormControl>
                                            <span className="error-msg">
                                                {errors.action}
                                            </span>
                                        </div>
                                        <div className="mb_15">
                                            <TextField
                                                type="number"
                                                name="name"
                                                onChange={radius}
                                                value={geofencestate.radius}
                                                id="standard-basic"
                                                label="Radius/Buffer (Meters)"
                                                className="col-md-12"
                                            />
                                        </div>


                                        <div className="mb_15">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField
                                                        type="text"
                                                        name="name"
                                                        value={

                                                            geofencestatelatlng.geofencelat
                                                        }
                                                        id="standard-basic"
                                                        label="Lat(DD)"
                                                        className="col-md-12"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextField
                                                        type="text"
                                                        name="name"
                                                        value={
                                                            geofencestatelatlng.geofencelng
                                                        }
                                                        id="standard-basic"
                                                        label="Lon(DD)"
                                                        className="col-md-12"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb_15">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Checkbox color="primary" />}
                                                        label="Enable"
                                                        labelPlacement="end"
                                                        onChange={enablecheckbox}
                                                    />
                                                </div>
                                                {geofencestate.enable === true ? (
                                                    <>
                                                        <div className="col-md-4">
                                                            <FormControlLabel
                                                                value="1"
                                                                control={
                                                                    <Checkbox color="primary" />
                                                                }
                                                                label="Entry"
                                                                labelPlacement="end"
                                                                onChange={entryEnable}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <FormControlLabel
                                                                value="1"
                                                                control={
                                                                    <Checkbox color="primary" />
                                                                }
                                                                label="Exit"
                                                                labelPlacement="end"
                                                                onChange={exitEnable}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mb_15">
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    name="name"
                                                    onChange={angleEntry}
                                                    value={geofencestate.angleEntry}
                                                    id="standard-basic"
                                                    label="IN Angle"
                                                    className="col-md-12"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="text"
                                                    name="name"
                                                    onChange={gapEntry}
                                                    value={geofencestate.gapEntry}
                                                    id="standard-basic"
                                                    label="IN Gap"
                                                    className="col-md-12"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb_15">
                                            <div className="col-md-6">
                                                <TextField
                                                    type="number"
                                                    name="name"
                                                    onChange={angleExit}
                                                    value={geofencestate.angleExit}
                                                    id="standard-basic"
                                                    label="OUT Angle"
                                                    className="col-md-12"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextField
                                                    type="number"
                                                    name="name"
                                                    onChange={gapExit}
                                                    value={geofencestate.gapExit}
                                                    id="standard-basic"
                                                    label="OUT Gap"
                                                    className="col-md-12"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex_div justify_sb">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={isSubmitting}
                                                color="secondary"
                                                className="ps_btn"
                                            >
                                                Add Geofence
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="ps_btn"
                                                onClick={ReInitializeGeofence}
                                            >
                                                Cancel / Clear
                                            </Button>
                                        </div>
                                    </div>
                                </form>


                            )}
                        </Formik>
                        <div >
                            {geofenceResponse !== "" &&
                                geofenceResponse !== undefined &&
                                geofenceResponse !== null ? (
                                geofenceResponse > 0 ? (
                                    <Snackbar
                                        open={geofenceopen}
                                        autoHideDuration={3000}
                                        onClose={handleGeofenceinfoClose}
                                        className="col-md-12"
                                    >
                                        <Alertinfo onClose={handleGeofenceinfoClose} severity="success">
                                            Geofence Saved Successfully!
                                        </Alertinfo>
                                    </Snackbar>
                                ) : (geofenceResponse === -1 || geofenceResponse <= 0) && geofenceResponse !== -2 ?
                                    <Snackbar
                                        open={geofenceopen}
                                        autoHideDuration={3000}
                                        onClose={handleGeofenceinfoClose}
                                        className="col-md-12"
                                    >
                                        <Alertinfo onClose={handleGeofenceinfoClose} severity="error">
                                            Saving Failed.
                                        </Alertinfo>
                                    </Snackbar>
                                    : geofenceResponse === -2 ?
                                        <Snackbar
                                            open={geofenceopen}
                                            autoHideDuration={3000}
                                            onClose={handleGeofenceinfoClose}
                                            className="col-md-12"
                                        >
                                            <Alertinfo onClose={handleGeofenceinfoClose} severity="error">
                                                Duplicate Entry. Please check Geofence name
                                            </Alertinfo>
                                        </Snackbar>
                                        : ""
                            ) : Array.isArray(geofenceResponse) === false ?
                                geofenceResponse === "" &&
                                <Snackbar
                                    open={geofenceopen}
                                    autoHideDuration={3000}
                                    onClose={handleGeofenceinfoClose}
                                    className="col-md-12"
                                >
                                    <Alertinfo onClose={handleGeofenceinfoClose} severity="error">
                                        An error occured while saving the data
                                    </Alertinfo>
                                </Snackbar>
                                : ""
                            }
                        </div>

                    </div>
                </div>
            </div>
            :
            <>
                <div className="col-md-12 text-right">
                    <Button
                        variant="contained"
                        color="secondary"
                        className="ps_btn"
                    >
                        Please right click on the map and select "Add as geofence" to pick the
                        center point.
                    </Button>
                </div>
            </>
    )
}
export default AddGeofencebody;