// import axios from 'axios';
import { toAbsoluteUrl } from "../utils/utils";

function StaticItem() {
  return {
    aside: {
      items: [
        {
          id: 42,
          parentId: 0,
          name: "Home",
          checked: true,
          type: "parent",
          root: true,
          icon: toAbsoluteUrl("/media/menuicons/Home.svg"),
          page: "Liveview",
          bullet: "dot",
          toggle: "click",
          children: [
            {
              id: 46,
              parentId: 42,
              functionName: "treeview",
              checked: true,
              type: "child",
              name: "Live View",
              page: "Liveview",
            },
            {
              id: 47,
              parentId: 42,
              name: "Panel Search",
              checked: true,
              type: "child",
              page: "panelsearch/PanelSearch",
            },
          ],
        },
        {
          id: 111,
          parentId: 0,
          name: "Dashboard",
          root: true,
          checked: true,
          type: "parent",
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/Dashboard.svg"),
          toggle: "click",
          children: [
            {
              id: 1111,
              parentId: 111,
              type: "child",
              name: "Today's Status",
              page: "dashboard/Status",
              checked: true,
            },
            {
              id: 1112,
              parentId: 111,
              type: "child",
              name: "Yesterday's KFPI",
              page: "dashboard/KFPI",
              checked: true,
            },
            {
              id: 1113,
              parentId: 111,
              type: "child",
              name: "KFPI Settings",
              page: "dashboard/KFPISettings",
              checked: true,
            },
          ],
        },
        {
          id: 44,
          parentId: 0,
          name: "Report",
          checked: true,
          root: true,
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/Report.svg"),
          type: "parent",
          toggle: "click",
          children: [
            {
              id: 53,
              parentId: 44,
              name: "Activity Report",
              checked: true,
              type: "child",
              page: "report/ActivityReport",
            },
            {
              id: 54,
              parentId: 44,
              name: "Usage Report",
              checked: true,
              type: "child",
              page: "report/UsageReport",
            },
            {
              id: 55,
              parentId: 44,
              name: "Instant Event",
              checked: true,
              type: "child",
              page: "report/Eventreport",
            },
            {
              id: 56,
              parentId: 44,
              name: "Instant I/O",
              checked: true,
              type: "child",
              page: "report/IOReport",
            },
            {
              id: 57,
              parentId: 44,
              name: "Report Factory",
              checked: true,
              type: "child",
              page: "report/ReportFactory",
            },
          ],
        },
        {
          title: "Fleet",
          id: 43,
          parentId: 0,
          name: "Fleet",
          checked: true,
          root: true,
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/fleet.svg"),
          type: "parent",
          page: "fleet/Fleet",
        },
        {
          id: 45,
          parentId: 0,
          name: "Mrp",
          checked: true,
          root: true,
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/mrp.svg"),
          type: "parent",
          toggle: "click",
          children: [
            {
              id: 65,
              parentId: 217,
              name: "PAX Assignment Tracking System",
              checked: true,
              type: "child",
              page: "mrp/PaxAssignmentTrackingSystem",
            },
            {
              id: null, // null because no corressponding functional tree id available
              parentId: 217,
              name: "Route and Jobs",
              checked: true,
              type: "child",
              page: "mrp/RouteAndJobs",
            },
           
          ],
        },
        {
          title: "Settings",
          id: 111,
          name: "Settings",
          root: true,
          checked: true,
          type: "parent",
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/settings.svg"),
          toggle: "click",
          children: [
            {
              id: 136,
              parentId: 10,
              name: "Geofence",
              checked: true,
              type: "child",
              page: "geofence/Geofence",
            },
            {
              id: 135,
              parentId: 10,
              name: "POI",
              checked: true,
              type: "child",
              page: "geofence/POI",
            },
            {
              id: 48,
              parentId: 10,
              name: "Manage Account",
              checked: true,
              type: "child",
              page: "manageaccount/ManageAccount",
            },
            {
              id: 49,
              parentId: 10,
              name: "Manage Settings",
              checked: true,
              type: "child",
              page: "managesettings/ManageSettings",
            },
          ],
        },
       
        {
          title: "Support",
          id: 101,
          parentId: 0,
          name: "Support",
          checked: true,
          root: true,
          bullet: "dot",
          icon: toAbsoluteUrl("/media/menuicons/support.svg"),
          type: "parent",
          page: "Support",
        },
      ],
    },
  };
}

//static call............
export const MenuConfig = StaticItem();

// let MenuLayoutConfig = JSON.parse(JSON.stringify(getItem)); // deep object copy

export default MenuConfig;
