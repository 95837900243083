// To Fetech the report dropdown options from redux store
import axios from "axios";
import { serverurl } from "../../../router/config";

export const assetDropdown = async () => {
  return await axios.get(serverurl + "getTreeViewList?vehicleIds=-1");
};

export const driverDropdown = async () => {
  return await axios.get(serverurl + "getDriverTreeViewForReport");
};

export const psasset = async () => {
  return await axios.get(serverurl + "populateHistoryAssets?target_search=-1");
};

export const psdrivers = async () => {
  return await axios.get(serverurl + "populateDrivers?target_search=-1");
};

