import * as i18n from "./ducks/i18n";
import * as builder from "./ducks/builder";
import * as functionaltree from "./ducks/functionaltree";
import * as quickoptions from "./ducks/quickoptions";
// import * as sidebardashboard from "./ducks/sidebardashboard";
import * as liveView from "./ducks/liveView";
import * as maplayer from "./ducks/maplayer";

/**
 * Reexports
 */
export * from "./utils/utils";
export * from "./layout/LayoutContext";
export * from "./layout/LayoutConfig";
export { default as LayoutConfig } from "./layout/LayoutConfig";
export { default as LayoutInitializer } from "./layout/LayoutInitializer";
export { default as I18nProvider } from "./i18n/I18nProvider";
export { default as ThemeProvider } from "./materialUIThemeProvider/ThemeProvider";

/**
 * Ducks
 */

export const infofleet = {
  i18n,
  builder,
  functionaltree,
  quickoptions,
  // sidebardashboard,
  liveView,
  maplayer
};
