import React, { useEffect } from 'react';
import {
    AutoCompleteComponent,
    CheckBoxSelection,
    Inject,
    MultiSelectComponent,
  } from "@syncfusion/ej2-react-dropdowns";

export default function CustomMultiSelectComponent(props) {
    const { fields,dataSource,placeholder,change,filterBarPlaceholder,name,...other } = props;
    return (
        <MultiSelectComponent
        popupHeight="300px"
        fields={fields}
        dataSource={dataSource}
        enableGroupCheckBox="true"
        placeholder={placeholder}
        mode="CheckBox"
        allowFiltering="true"
        change={change}
        changeOnBlur={false}
        showSelectAll="true"
        filterType="Contains"
        selectAllText="Select All"
        unSelectAllText="unSelect All"
        filterBarPlaceholder={filterBarPlaceholder}
        name={name}
        cssClass="mb__10"
        {...other}
      // selectedAll={poiidselectall}
      // select={poiidselectall}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
        
       
    )
}