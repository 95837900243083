import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../infofleet";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import FeedbackIcon from "../../../assets/infofleet/feedback.png";
import { ReactComponent as CartNum3Icon } from "../../../infofleet/layout/assets/layout-svg-icons/CartNum3.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import Loader from "../../pages/home/Loader";
import axios from "axios";
import { serverurl } from "../../router/config";
import _ from "lodash";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export default function Feedback(props) {
  const { bgImage, useSVG, icon, iconType } = props;
  const [loaderview, LoaderView] = React.useState("");
  const [feedbackresponse, setFeedbackResponse] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    feedbacktitle: "",
    feedbackcontent: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function titlechange(event) {
    setState({ ...state, feedbacktitle: event.target.value });
  }
  function contentchange(event) {
    setState({ ...state, feedbackcontent: event.target.value });
  }

  return (
    <>
      {loaderview === "true" ? <Loader show="true" /> : ""}
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-panel-tooltip"><span className="font-bold">Feedback:</span> Post your suggestions to improve the system.</Tooltip>}
      >
        <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-my-cart"
          >
            <span
              className={clsx("kt-header__topbar-icon", {
                "kt-header__topbar-icon--brand": iconType === "brand",
              })}
            >
              {!useSVG && <i className={icon} />}

              {useSVG && (
                <span
                  className={clsx("kt-svg-icon", {
                    "kt-svg-icon-brand": iconType === "brand",
                  })}
                >
                  <img
                    style={{ width: "24px" }}
                    src={FeedbackIcon}
                    alt="Feedback"
                    className="kt-svg-icon kt-svg-icon--primary"
                  />
                </span>
              )}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            <form className="flex-column p-0">
              <div className="kt-mycart">
                <div
                  className="kt-mycart__head kt-head"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  <div className="kt-mycart__info">
                    <h3 className="kt-mycart__title">Post Feedback</h3>
                  </div>
                </div>

                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{ maxHeight: "35vh", position: "relative" }}
                >
                  <div className="kt-mycart__body">
                    <div className="kt-mycart__item">
                      <div className="kt-mycart__container">
                        <div className="kt-mycart__body">
                          <Formik
                            enableReinitialize
                            initialValues={{}}
                            validate={(values) => {
                              const errors = {};
                              if (!state.feedbacktitle) {
                                errors.feedbacktitle = "Title is Required";
                              }
                              if (!state.feedbackcontent) {
                                errors.feedbackcontent = "Field is Required";
                              }
                              return errors;
                            }}
                            onSubmit={(
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              LoaderView("true");
                              setTimeout(() => {
                                const requestBody = {
                                  title: _.toString(state.feedbacktitle),
                                  content: _.toString(state.feedbackcontent),
                                };
                                const config = {
                                  headers: {
                                    "Content-Type":
                                      "application/json; charset=utf-8",
                                  },
                                };
                                axios
                                  .post(
                                    serverurl + "addFeedBack",
                                    requestBody,
                                    config
                                  )
                                  .then(function(response) {
                                    LoaderView("false");
                                    setOpen(true);
                                    setFeedbackResponse(response.data);
                                    setState({...state,
                                      feedbacktitle: "",
                                      feedbackcontent: "",
                                    });
                                  });
                                setSubmitting(false);
                              }, 300);
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                            }) => (
                              <form onSubmit={handleSubmit} className="mb_15">
                                <div className="row">
                                  <div className="col-md-12">
                                    <TextField
                                      name="feedbacktitle"
                                      id="titletextarea"
                                      label="Title"
                                      placeholder="Short, descriptive title"
                                      rowsMax={2}
                                      multiline
                                      className="col-md-12 p_0"
                                      onChange={titlechange}
                                      onBlur={handleBlur}
                                      value={state.feedbacktitle}
                                    />
                                    <span className="col-md-12 error-msg">
                                      {errors.feedbacktitle}
                                    </span>
                                  </div>
                                  <div className="col-md-12 mt_15">
                                    <TextField
                                      name="feedbackcontent"
                                      id="content"
                                      label="Details"
                                      placeholder="Any additional details..."
                                      multiline
                                      rowsMax={4}
                                      className="col-md-12 p_0"
                                      onChange={contentchange}
                                      onBlur={handleBlur}
                                      value={state.feedbackcontent}
                                    />
                                    <span className="col-md-12 error-msg">
                                      {errors.feedbackcontent}
                                    </span>
                                  </div>
                                  <div className="col-md-12 mt_15">
                                    <Button
                                      type="submit"
                                      disabled={isSubmitting}
                                      color="primary"
                                      variant="contained"
                                      className="float-right"
                                    >
                                      Submit Post
                                    </Button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </PerfectScrollbar>

                <div className="kt-mycart__footer">
                  <div className="kt-mycart__section">
                    <div className="col-md-12">
                      {feedbackresponse !== "" &&
                      feedbackresponse !== undefined &&
                      feedbackresponse !== null ? (
                        feedbackresponse !== -1 || feedbackresponse > 0 ? (
                          <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            className="col-md-12"
                          >
                            <Alert onClose={handleClose} severity="success">
                              Feedback Saved Successfully!
                            </Alert>
                          </Snackbar>
                        ) : (
                          <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            className="col-md-12"
                          >
                            <Alert onClose={handleClose} severity="error">
                              Saving Failed.
                            </Alert>
                          </Snackbar>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    </>
  );
}
