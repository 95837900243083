import L from "leaflet";
import { toAbsoluteUrl } from "../../../infofleet/utils/utils";

// Different Icons for Map Representation

export const MovingBikeIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/moving_bike.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/moving_bike.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
  });
export const ParkedBikeIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/parked_bike.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/parked_bike.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
  });
  export  const NosignalBikeIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/nosignal_bike.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/nosignal_bike.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
  });
  export const EngineONBikeIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/engine_on_bike.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/engine_on_bike.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
  });
  
  export const StoppedBikeIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/stopped_bike.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/stopped_bike.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [25, 25],
  });
  
  export const MovingpointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_green.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_green.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 30),
    iconAnchor: [5, 15],
  });
  export const ParkedpointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_blue.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_blue.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 30),
    iconAnchor: [5, 15],
  });
  
  export const StoppedpointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_orange.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_orange.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 30),
    iconAnchor: [5, 15],
  });
  export const NosignalpointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_yellow.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Car-icon_yellow.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 30),
    iconAnchor: [5, 15],
  });
  
  export const AnimatedIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/animatedicon.gif"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/animatedicon.gif"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 50),
    iconAnchor: [18, 20],
  });
  export const AnimatedIdleIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/idling_animation.gif"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/idling_animation.gif"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(40, 40),
    iconAnchor: [20, 20],
  });
  
  export const OverspeedIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Notification/info_red.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Notification/info_red.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [8, 50],
  });
  
  export const IdleIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Notification/info_yellow.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Notification/info_yellow.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [8, 50],
  });
  
  export const SelectedpointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/selectedpin.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/selectedpin.svg"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(15, 15),
    iconAnchor: [5, 8],
  });
  
  export const StarttrippointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/start_flag.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/start_flag.svg"),
    iconAnchor: [5, 45],
    popupAnchor: [10, -35],
    iconSize: [35, 55],
    iconSize: new L.Point(35, 55),
  });

  export const EndtrippointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/end_flag.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/end_flag.svg"),
    iconAnchor: [5, 45],
    popupAnchor: [10, -35],
    iconSize: [35, 55],
    iconSize: new L.Point(35, 55),
  });

    
  export const PSstoppointerIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Stopped_Marker.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Stopped_Marker.png"),
    iconAnchor: [16, 30],
    popupAnchor: [0, -35],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
  });

  export const ParkedMarker = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/Parked_Marker.png"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/Parked_Marker.png"),
    iconAnchor: [16, 30],
    popupAnchor: [0, -35],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
  });

  export const NavigationIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/history.svg"),
    iconRetinaUrl: toAbsoluteUrl("/media/icons/Mapicons/history.svg"),
    iconAnchor: [15, 37],
    popupAnchor: [0, -35],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(75, 75),
  });

  export const ExcessiveIdleIcon = new L.Icon({
    iconUrl: toAbsoluteUrl("/media/icons/Mapicons/excessiveidle.png"),
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 40),
    iconAnchor: [13, 20],
  });

  export const FlagIcon = new L.Icon({
    iconUrl: toAbsoluteUrl(
      "/media/icons/Mapicons/Map-Marker-Flag-1-Right-Pink-icon.png"
    ),
    iconRetinaUrl: toAbsoluteUrl(
      "/media/icons/Mapicons/Map-Marker-Flag-1-Right-Pink-icon.png"
    ),
    popupAnchor: [10, -25],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(50, 50),
    iconAnchor: [5, 45],
  });

  // Different Icons for Map Representation