/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as maplayerAction from "../../../infofleet/ducks/maplayer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import MapIcon from "../../../assets/infofleet/informap_maps.png";
import InformapMapIcon from "../../../assets/infofleet/informap_maps.png";
import GoogleMapIcon from "../../../assets/infofleet/google-maps.png";
import axios from "axios";
import { serverurl } from "../../router/config";

function MapActionsPanel(props) {
  // render() {
  const { icon, useSVG, bgImage, iconType, gridNavSkin } = props;
  const [state, setState] = React.useState({
    selectedlayer: "informap",
  });
  const dispatch = useDispatch();
  function mapselect(event) {
    dispatch(maplayerAction.actions.ClusterView(false));
    dispatch(maplayerAction.actions.MapLayer(event));
    setState({ ...state, selectedlayer: event });
  }

  useEffect(()=>{
    axios.get(serverurl + 'getCustomerMapList').then(function(response) {
      let googleMapdata = response.data ? (response.data).filter(item => item.id === 2 ) : ""
      if(googleMapdata && googleMapdata.length>0){
        if(googleMapdata[0].isDefault === true){
          mapselect("google")
        }
      }
    });
  },[])

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-panel-tooltip"><span className="font-bold">Map Layer:</span> Switch Map Source. Advised to use Google map sparingly, as it is not Free!</Tooltip>}
    >
      <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-quick-actions-panel-toggle"
        >
          <span
            className={clsx(`kt-header__topbar-icon`, {
              "kt-header__topbar-icon--warning": iconType === "warning",
            })}
          >
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span
                className={clsx("kt-svg-icon", {
                  "kt-svg-icon--warning": iconType === "warning",
                })}
              >
                <img
                  style={{ width: "24px" }}
                  src={MapIcon}
                  alt="Maps"
                  className="kt-svg-icon kt-svg-icon--primary"
                />
              </span>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <form className="flex-column p-0">
            {/* begin: Head */}
            <div
              className={`kt-head kt-head--skin-${{ gridNavSkin }}`}
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h3 className="kt-head__title text-white">Select Map</h3>
            </div>
            {/* end: Head */}

            <div className={`kt-grid-nav kt-grid-nav--skin-${gridNavSkin}`}>
              <div className="kt-grid-nav__row">
                <a
                  className="kt-grid-nav__item"
                  onClick={(e) => mapselect("informap")}
                >
                  <img
                    style={{ width: "60px", height: "60px" }}
                    src={InformapMapIcon}
                    alt="Maps"
                    className="kt-svg-icon kt-svg-icon--primary"
                  />
                  <span className="kt-grid-nav__title">Informap</span>
                </a>

                <a
                  className="kt-grid-nav__item"
                  onClick={(e) => mapselect("google")}
                >
                  <div className="d-flex align-items-center flex-column">
                    <span className="kt-grid-nav__icon">
                      <img
                        style={{ width: "60px", height: "60px" }}
                        src={GoogleMapIcon}
                        alt="Maps"
                        className="kt-svg-icon kt-svg-icon--primary"
                      />
                    </span>
                    <span className="kt-grid-nav__title">Google</span>
                  </div>
                </a>
              </div>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger>
  );
  // }
}
const mapStateToProps = ({ maplayer }) => ({
  maplayer,
});

export default connect(mapStateToProps, { maplayerAction })(MapActionsPanel);
