// Custom Tooltip for Map Icons Hover
import * as React from "react";
export default function CustomMapTooltip(props) {
  return (
    <div className="custommaptooltip">
      {props.plateno !== "" &&
      props.plateno !== undefined &&
      props.plateno !== null ? (
        <div className="row bb plateno_row">
          <div className="col-md-5 left-side">Plate</div>{" "}
          <div className="col-md-7 font-700"> {props.plateno}</div>
        </div>
      ) : (
        ""
      )}

      {props.issuedby !== "" &&
      props.issuedby !== undefined &&
      props.issuedby !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Issued By</div>{" "}
          <div className="col-md-7"> {props.issuedby}</div>
        </div>
      ) : (
        ""
      )}

      {props.make !== "" &&
      props.make !== undefined &&
      props.make !== null &&
      props.model !== "" &&
      props.model !== undefined &&
      props.model !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Make - Model</div>{" "}
          <div className="col-md-7">
            {" "}
            {props.make} - {props.model}
          </div>
        </div>
      ) : (
        ""
      )}

      {props.driver !== "" &&
      props.driver !== undefined &&
      props.driver !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Driver </div>
          <div className="col-md-7"> {props.driver}</div>
        </div>
      ) : (
        ""
      )}

      {props.phone !== "" &&
      props.phone !== undefined &&
      props.phone !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Mobile </div>
          <div className="col-md-7"> {props.phone}</div>
        </div>
      ) : (
        ""
      )}

      {props.speed !== "" &&
      props.speed !== undefined &&
      props.speed !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Speed</div>
          <div className="col-md-7"> {props.speed} Km/h</div>
        </div>
      ) : (
        ""
      )}

      {props.time !== "" && props.time !== undefined && props.time !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Date Time </div>
          <div className="col-md-7"> {props.time}</div>
        </div>
      ) : (
        ""
      )}

      {props.temp1 !== "" &&
      props.temp1 !== undefined &&
      props.temp1 !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Tsensor1 </div>
          <div className="col-md-7">
            {" "}
            {props.temp1 === "" ? "NA" : props.temp1}
          </div>
        </div>
      ) : (
        ""
      )}

      {props.temp2 !== "" &&
      props.temp2 !== undefined &&
      props.temp2 !== null ? (
        <div className="row bb">
          <div className="col-md-5 left-side">Tsensor2 </div>
          <div className="col-md-7">
            {" "}
            {props.temp2 === "" ? "NA" : props.temp2}
          </div>
        </div>
      ) : (
        ""
      )}

      {props.geofencename !== "" &&
      props.geofencename !== undefined &&
      props.geofencename !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Geofence Name</div>{" "}
          <div className="col-md-6"> {props.geofencename}</div>
        </div>
      ) : (
        ""
      )}

      {props.grl !== "" && props.grl !== undefined && props.grl !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">GRL</div>{" "}
          <div className="col-md-6"> {props.grl}</div>
        </div>
      ) : (
        ""
      )}

      {props.poiname !== "" &&
      props.poiname !== undefined &&
      props.poiname !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">POI Name</div>{" "}
          <div className="col-md-6"> {props.poiname}</div>
        </div>
      ) : (
        ""
      )}
      {props.poicode !== "" &&
      props.poicode !== undefined &&
      props.poicode !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">POI Code</div>{" "}
          <div className="col-md-6"> {props.poicode}</div>
        </div>
      ) : (
        ""
      )}
      {props.poigroup !== "" &&
      props.poigroup !== undefined &&
      props.poigroup !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Group</div>{" "}
          <div className="col-md-6"> {props.poigroup}</div>
        </div>
      ) : (
        ""
      )}

      {props.location !== "" &&
      props.location !== undefined &&
      props.location !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Location</div>{" "}
          <div className="col-md-6"> {props.location}</div>
        </div>
      ) : (
        ""
      )}
      {props.status !== "" &&
      props.status !== undefined &&
      props.status !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Status</div>{" "}
          <div className="col-md-6"> {props.status}</div>
        </div>
      ) : (
        ""
      )}
      {props.duration !== "" &&
      props.duration !== undefined &&
      props.duration !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Duration</div>{" "}
          <div className="col-md-6"> {props.duration}</div>
        </div>
      ) : (
        ""
      )}
      {props.date !== "" && props.date !== undefined && props.date !== null ? (
        <div className="row bb bt">
          <div className="col-md-6 left-side">Date</div>{" "}
          <div className="col-md-6"> {props.date}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
