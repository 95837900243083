import React from 'react'
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from 'axios';
import moment from 'moment';
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  serverleturl,
  serverurl,
} from "../../router/config";
import _ from "lodash";
import { endOfToday, startOfToday } from "date-fns";
import SwipeableViews from "react-swipeable-views";
import DriverImg from "../../../assets/infofleet/driver_template.png";
import CarImg from "../../../assets/infofleet/car.png";
import { Formik } from "formik";
import { OverlayTrigger,Tooltip} from "react-bootstrap";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { connect, useDispatch } from "react-redux";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import ModalImage from "react-modal-image";
import SMSImg from "../../../assets/infofleet/sms.png";
  // Validation Schema for Phone no
  const Schema = Yup.object().shape({
    phoneno: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
  });
  // Validation Schema for Phone no

  // Single Asset Info
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className="tabpanelsection"
        {...other}
      >
        {value === index && (
          <Box className="tab_section" p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  
  // Single Asset Info


function SingleAssetInfo(props) {
  const { singleassetdata, closesingleasset, deviceuniqhueid , liveView,handleFocusTrackClick ,  InputEventStatus} = props

  const [states, setState] = React.useState({
    sendMode: "1",
    viewlimit: "",
    expiryduration: "",
    phoneno: "",
    focustrack:false


  });


  const [focusloader, setfocusloader] = React.useState(false);
  const [assetinfotab, setAssetinfoTab] = React.useState(0);
  const [Timelinedata, setTimelinedata] = React.useState("");
  const [timelineloader, setTimelineLoader] = React.useState(false);
  var focustrackChecked;
  const CHARACTER_LIMIT = 125;

  const [alertstatus, AlertStatus] = React.useState({
    sharelocationstatus: "",
  });
  const [alerthide, AlertHide] = React.useState();
  const alertshow = (value) => {
    AlertHide(value);
    setTimeout(function () {
      AlertHide(false);
    }, 1000);
  };




  // Time Line Section
  const today = moment();
  const expiryservicegraphcolor = [];
  if (
    Timelinedata !== "" &&
    Timelinedata !== undefined &&
    Timelinedata !== null
  ) {
    Timelinedata.forEach((ele, index) => {
      var color =
        ele.vmode === "Parked"
          ? "#157dec"
          : ele.vmode === "Stopped"
            ? "#ff862c"
            : ele.vmode === "Moving"
              ? "#7fff00"
              : ele.vmode === "Idling Engine ON"
                ? "#EC0004"
                : "#157dec";
      expiryservicegraphcolor.push(color);
    });
  }
  let Timelinecontent = [];
  if (
    Timelinedata !== "" &&
    Timelinedata !== undefined &&
    Timelinedata !== null
  ) {
    for (let i = 0; i < Timelinedata.length - 1; i++) {
      var timeline_starttime = Timelinedata[i].dateTime.split(" ");
      var timeline_endtime = Timelinedata[i + 1].dateTime.split(" ");
      var timeline_starttime_1 =
        today.format("YYYY-MM-DD") + " " + timeline_starttime[1];
      var timeline_endtime_1 =
        today.format("YYYY-MM-DD") + " " + timeline_endtime[1];
      Timelinecontent.push({
        name: Timelinedata[i].vmode,
        data: [
          {
            x: "Timeline",
            y: [
              new Date(timeline_starttime_1).getTime(),
              new Date(timeline_endtime_1).getTime(),
            ],
          },
        ],
      });
    }
  } else {
  }
  var newgraph = {
    series: Timelinecontent,
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          rangeBarGroupRows: true,
        },
      },
      colors: expiryservicegraphcolor,
      fill: {
        type: "solid",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            return dayjs(val).format("HH:mm");
          },
        },
      },
      legend: {
        show: false,
        position: "bottom",
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: "HH:MM",
          formatter: function (val) {
            return dayjs(val).format("HH:mm");
          },
        },
      },
    },
  };
  // Time Line Section




  // Share location Mode Change
  function sharemodechange(event) {
    setState({ ...states, sendMode: event.target.value });
  }
  // Share location Mode Change


  // Google Earth
  function googleearthsubmit() {
    var xmlurl = `${serverleturl}CreateKmlServlet?format=KML&plateNo=${singleassetdata.plateNo
      }&x=${singleassetdata.longitude}&y=${singleassetdata.latitude}&assetName=${singleassetdata.assetName
      }&stat=${singleassetdata.vmode}&Location=${_.toString(
        encodeURIComponent(singleassetdata.location)
      )}&dateTime=${singleassetdata.dateTime}&speed=${singleassetdata.speed}`;

    window.location = xmlurl;
  }
  // Google Earth



  // Single Asset Info Section
  const InfoTabChange = (event, newValue) => {
    setAssetinfoTab(newValue);
  };

  const handleChangeInfoIndex = (index) => {
    setAssetinfoTab(index);
  };

  function tabTimelineSelect(event) {
    setTimelinedata("");
    var DateFrom = startOfToday();
    var DateTo = endOfToday();
    var datefrom = moment(DateFrom).format("YYYY-MM-DD H:m:ss");
    var dateto = moment(DateTo).format("YYYY-MM-DD H:m:ss");
    setTimelineLoader(true);
    axios
      .get(serverurl + "getSearchGridData", {
        params: {
          target_param: "Asset",
          target_search: _.toString(deviceuniqhueid),
          fromdate: datefrom,
          todate: dateto,
          quickhistorytimeid: 4,
          quickhist: false,
          isAll: 1,
        },
      })
      .then(function (response) {
        setTimelineLoader(false);
        setTimelinedata(response.data);
      });
  }
  // Single Asset Info Section

  // Function for Focus Track Click
  function focustrackclick(event) {
    setfocusloader(true);
    setState({...states,focustrack:event.target.checked})
    localStorage.setItem("showtrack", event.target.checked);
    localStorage.setItem(
      "trackvehicleid",
      JSON.stringify(liveView.selectedVId.vehicleId)
    );

    // setState({
    //   ...states,
    //   focustrack: event.target.checked,
    // });
    var DateFrom = startOfToday();
    var DateTo = endOfToday();
    var datefrom = moment(DateFrom).format("YYYY-MM-DD H:m:ss");
    var dateto = moment(DateTo).format("YYYY-MM-DD H:m:ss");
    var existingEntries = [];
    if (event.target.checked === true) {
      axios
        .get(serverurl + "getSearchGridData", {
          params: {
            target_param: "Asset",
            target_search: liveView.selectedVId.device_uniqueid,
            fromdate: _.toString(datefrom),
            todate: _.toString(dateto),
            quickhistorytimeid: 4,
            quickhist: false,
            isAll: 1,
          },
        })
        .then(function(response) {
          // var lastenginestatusdata = _.last(
          //   response.data.filter(function(obj) {
          //     return obj.currentStatus === "Parked";
          //   })
          // );

          var lastenginestatuspath = _.takeRightWhile(response.data, function(
            o
          ) {
            return o.currentStatus !== "Parked";
          });

          if (lastenginestatuspath.length <= 1) {
            localStorage.setItem(
              "previoustrack",
              JSON.stringify(lastenginestatuspath)
            );
          } else {
            localStorage.setItem(
              "previoustrack",
              JSON.stringify(lastenginestatuspath)
            );
          }

          // existingEntries.unshift(lastenginestatusdata);
          // localStorage.setItem("allEntries", JSON.stringify(existingEntries));
        });

      axios
        .get(serverurl + "getCurrentStatusDetails", {
          params: {
            vehicleId: liveView.selectedVId.vehicleId,
          },
        })
        .then(function(response) {
          existingEntries.push(response.data);
          localStorage.setItem("allEntries", JSON.stringify(existingEntries));
        });

      focustrackChecked = event.target.checked ? "true" : "false ";
      setTimeout(() => {
        var existingTrackdata = JSON.parse(localStorage.getItem("allEntries"));
        var PreviousTrackdata = JSON.parse(
          localStorage.getItem("previoustrack")
        );
        
        handleFocusTrackClick(focustrackChecked,existingTrackdata,PreviousTrackdata)

        setfocusloader(false);
      }, 1000);
    } else {
      localStorage.removeItem("allEntries");
      localStorage.removeItem("previoustrack");
      localStorage.removeItem("showtrack");
      localStorage.removeItem("trackvehicleid");
     
      focustrackChecked = "false"
      handleFocusTrackClick(focustrackChecked,null,null)
      setfocusloader(false);
    }
  }


  function closesingleassetdata(){
    setAssetinfoTab(0)
    setState({...states,focustrack:false})
    setfocusloader(false)
    closesingleasset()
  }

  return (
    <>
    <div>
     
          <div
            className={`${singleassetdata.vmode === "Moving"
                ? "liveview_label_moving"
                : singleassetdata.vmode === "Parked"
                  ? "liveview_label_parked"
                  : singleassetdata.vmode === "Stopped"
                    ? "liveview_label_stopped"
                    : singleassetdata.vmode === "Idling Engine ON"
                      ? "liveview_label_engineon"
                      : singleassetdata.vmode === "No Signal"
                        ? "liveview_label_nosignal"
                        : ""
              } single_asset_info pull-right `}
          >
            <span>{singleassetdata.plateNo}</span>
            <i
              className="la la-close single_asset_info_closeicon ml_10"
              onClick={closesingleassetdata}
            />
          </div>
          <AppBar position="static" color="default" className="e-tab-header">
            <Tabs
              className="e-items"
              value={assetinfotab}
              onChange={InfoTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                className="e-item e-tab-text"
                label="Vehicle Info"
                {...a11yProps(0)}
              />
              <Tab
                className="e-item e-tab-text"
                label="View Timeline"
                {...a11yProps(1)}
                onClick={tabTimelineSelect}
              />
              <Tab
                className="e-item e-tab-text"
                label="KML"
                {...a11yProps(2)}
              />
              <Tab
                className="e-item e-tab-text"
                label="Share Location"
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={assetinfotab}
            onChangeIndex={handleChangeInfoIndex}
            className="e-content"
          >
            <TabPanel value={assetinfotab} index={0}>
              {/* Vehicle Info */}
              <>
                <div>
                  <div className="vehicleinfo_info">
                    <span className="infospan">
                      Vehicle Info: Comprehensive View
                    </span>
                  </div>
                  <div className="row fleet_body">
                    <div className="col-md-4 fleet_details">
                      <h6 className="text-center">Fleet Details</h6>
                      <div className="row">
                        <div className="col-md-6 text-left">Present Status</div>
                        <div className="col-md-6">
                          :{" "}
                          {singleassetdata.vmode === "Idling Engine ON"
                            ? "Idling Eng ON"
                            : singleassetdata.vmode}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Plate No</div>
                        <div className="col-md-6 breakword">
                          : {singleassetdata.plateNo}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Brand</div>
                        <div className="col-md-6">
                          : {singleassetdata.assetMake}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Model</div>
                        <div className="col-md-6">
                          : {singleassetdata.assetModel}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Registration</div>
                        <div className="col-md-6">
                          : {singleassetdata.emirate}
                        </div>
                      </div>
                      <div className="row nowrap">
                        <div className="col-md-6 text-left">Group</div>
                        <div className="col-md-6">
                          : {singleassetdata.groupName}
                        </div>
                      </div>
                      <div className="row nowrap">
                        <div className="text-left">
                          {" "}
                          Last Reported Date & Time : {singleassetdata.dateTime}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 status_details">
                      <h6 className="text-center">Duration (HH:MM:SS)</h6>
                      <div className="row">
                        <div className="col-md-6 text-left">
                          Status Duration
                        </div>
                        <div className="col-md-6 status_font">
                          : {singleassetdata.activeDuration}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Today Moved</div>
                        <div className="col-md-6 status_font">
                          : {singleassetdata.movedDuration}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Today Idle</div>
                        <div className="col-md-6 status_font">
                          : {singleassetdata.stoppedDuration}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Today Parked</div>
                        <div className="col-md-6 status_font">
                          : {singleassetdata.parkedDuration}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 text-left">Today Distance</div>
                        <div className="col-md-6 status_font">
                          : {singleassetdata.totalDistance} KM
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-center">
                      <h6 className="text-center">Driver Details</h6>
                      <div className="row m_20_0">
                        <div className="col-md-12 text-center">
                          <ModalImage
                            small={
                              singleassetdata.driverPhoto !== "" &&
                                singleassetdata.driverPhoto !== undefined &&
                                singleassetdata.driverPhoto !== null
                                ? serverleturl +
                                "ImageServlet?type=driver&name=" +
                                singleassetdata.driverPhoto
                                : DriverImg
                            }
                            large={
                              singleassetdata.driverPhoto !== "" &&
                                singleassetdata.driverPhoto !== undefined &&
                                singleassetdata.driverPhoto !== null
                                ? serverleturl +
                                "ImageServlet?type=driver&name=" +
                                singleassetdata.driverPhoto
                                : DriverImg
                            }
                            alt="Driver Image"
                          />
                          <div className="focustrack"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 text-center breakword">
                          {singleassetdata.driverName}
                        </div>
                      </div>
                      <div className="focustrack_div">
                        {focusloader === true ? (
                          <CircularProgress className="focusloader" />
                        ) : (
                          ""
                        )}

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={states.focustrack}
                              onChange={focustrackclick}
                              name="focustrack"
                              color="primary"
                              className={focusloader === true ? "hide" : "show"}
                            />
                          }
                          className="font-bold"
                          label="Focus Track"
                        />
                      </div>
                    </div>
                    <div className="row font-11">
                      <div className="col-md-12 text-left">
                        Location : {singleassetdata.location}
                      </div>
                    </div>
                  </div>
                  <div className="fleet_footer">
                    <div className="col-md-12 fleet_footer_details">
                      <div className="row ">
                        <div className="col-md-2 text-center after_dash">
                          <ModalImage
                            small={
                              singleassetdata.vehiclePhoto !== "" &&
                                singleassetdata.vehiclePhoto !== undefined &&
                                singleassetdata.vehiclePhoto !== null
                                ? serverleturl +
                                "ImageServlet?type=asset&name=" +
                                singleassetdata.vehiclePhoto
                                : CarImg
                            }
                            large={
                              singleassetdata.vehiclePhoto !== "" &&
                                singleassetdata.vehiclePhoto !== undefined &&
                                singleassetdata.vehiclePhoto !== null
                                ? serverleturl +
                                "ImageServlet?type=asset&name=" +
                                singleassetdata.vehiclePhoto
                                : CarImg
                            }
                            alt="Asset Image"
                          />
                          {/* <img
                          className="fleet_footer_carimg"
                          alt="Car logo"
                          src={
                            singleassetdata.vehiclePhoto !== "" &&
                            singleassetdata.vehiclePhoto !== undefined &&
                            singleassetdata.vehiclePhoto !== null
                              ? serverleturl +
                                "ImageServlet?type=asset&name=" +
                                singleassetdata.vehiclePhoto
                              : CarImg
                          }
                        /> */}
                        </div>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6 after_dash">
                                  <div className="row">
                                    <span className="col-md-6 text-left p_0">
                                      Speed
                                    </span>
                                    <span className="col-md-6">
                                      : {singleassetdata.speed}/Kmph
                                    </span>
                                  </div>
                                  <div className="row nowrap">
                                    <span className="col-md-6 text-left p_0">
                                      Posted Speed
                                    </span>
                                    <span className="col-md-6">
                                      :{" "}
                                      {singleassetdata.postedSpeed === "" ||
                                        singleassetdata.postedSpeed === null ||
                                        singleassetdata.postedSpeed === undefined
                                        ? "NA"
                                        : singleassetdata.postedSpeed}
                                      /Kmph
                                    </span>
                                  </div>
                                  <div className="row">
                                    <span className="col-md-6 text-left p_0">
                                      Temp 1
                                    </span>
                                    <span className="col-md-6">
                                      : {singleassetdata.tSensor1}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6 after_dash">
                                  <div className="row">
                                    <span className="col-md-6 text-left p_0">
                                      GPS
                                    </span>
                                    <span className="col-md-6">
                                      : {singleassetdata.gps}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <span className="col-md-6 text-left p_0">
                                      Odometer
                                    </span>
                                    <span className="col-md-6">
                                      : {singleassetdata.currentOdometer}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <span className="col-md-6 text-left p_0">
                                      Temp 2
                                    </span>
                                    <span className="col-md-6">
                                      : {singleassetdata.tSensor2}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="df">
                                {InputEventStatus !== "" &&
                                  InputEventStatus !== undefined &&
                                  InputEventStatus !== null &&
                                  InputEventStatus.length > 0
                                  ? InputEventStatus.map((item, index) => {
                                    return (
                                      <div
                                        className="col-md-6"
                                        key={index + 1}
                                      >
                                        {item.alertName}
                                      </div>
                                    );
                                  })
                                  : ""}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="row">
                                <span className="col-md-6 text-left p_0">
                                  Voltage
                                </span>
                                <span className="col-md-6">
                                  : {singleassetdata.powerVoltage}
                                </span>
                              </div>
                              <div className="row">
                                <span className="col-md-6 text-left p_0">
                                  Fuel Usage
                                </span>
                                <span className="col-md-6">
                                  : {singleassetdata.fuelUsage}
                                </span>
                              </div>
                              <div className="row">
                                <span className="col-md-6 text-left p_0">
                                  Tank 1
                                </span>
                                <span className="col-md-6">
                                  : {singleassetdata.fSensor1}
                                </span>
                              </div>
                              <div className="row">
                                <span className="col-md-6 text-left p_0">
                                  Tank 2
                                </span>
                                <span className="col-md-6">
                                  : {singleassetdata.fSensor2}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </TabPanel>
            <TabPanel value={assetinfotab} index={1}>
              {/* View Timeline */}
              <>
                <div className="vehicleinfo_info">
                  <span className="infospan">
                    View Timeline: Graphical View of Today's Engine Status
                  </span>
                </div>
                <div id="chart" className="timeline_chart">
                  {timelineloader === true ? (
                    <div className="timeline_graph kt-notification-v2">
                      <a href="#" className="kt-notification-v2__item">
                        <CircularProgress />
                        <div className="kt-notification-v2__itek-wrapper">
                          <div className="kt-notification-v2__item-title">
                            Please wait a minute, there is so much data to
                            process and show you!
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {Timelinedata !== "" &&
                    Timelinedata !== undefined &&
                    Timelinedata.length !== 0 ? (
                    <>
                      <div className="col-md-12 text-center mt_20">
                        Timeline for Plate No -{" "}
                        <span className="font-bold">
                          {singleassetdata.plateNo}
                        </span>
                      </div>
                      {/* <div>
                        <span className="fromhr_timeline font-bold">
                          {Timelinedata[0].hr} H : {Timelinedata[0].min} M
                        </span>
                        <span className="tohr_timeline font-bold">
                          {Timelinedata[Timelinedata.length - 1].hr} H :{" "}
                          {Timelinedata[Timelinedata.length - 1].min} M{" "}
                        </span>
                      </div> */}
                      <div className="col-md-12 tg">
                        <ReactApexChart
                          options={newgraph.options}
                          series={newgraph.series}
                          type="rangeBar"
                          height={150}
                        />
                        {/* <ReactApexChart
                          options={timelinegraph.options}
                          series={timelinegraph.series}
                          type="bar"
                          height="100"
                          width="100%"
                        /> */}
                      </div>
                      <div className="timeline_graph_legend mb_20">
                        <div>
                          <span className="moving_box"></span>
                          <span>Moving</span>
                        </div>
                        <div>
                          <span className="parked_box"></span>
                          <span>Parked</span>
                        </div>
                        <div>
                          <span className="stoped_box"></span>
                          <span>Stopped</span>
                        </div>
                        <div>
                          <span className="idleengineon_box"></span>
                          <span>Idling Engine ON</span>
                        </div>
                      </div>
                    </>
                  ) : Timelinedata !== "" &&
                    Timelinedata !== undefined &&
                    Timelinedata.length === 0 ? (
                    <div className="timeline_graph kt-notification-v2">
                      <a href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__itek-wrapper">
                          <div className="kt-notification-v2__item-title">
                            There is no Timeline Data
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </TabPanel>
            <TabPanel value={assetinfotab} index={2}>
              {/* KML */}
              <>
                {/* <div className="vehicleinfo_info">
                  <span className="infospan">
                    KML : Generate KML file to view in google earth
                  </span>
                </div> */}
                <div>
                  <div className="p_20">
                    <h5 className="mb_20">Download vehicle location as KML</h5>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={googleearthsubmit}
                    >
                      Download KML
                    </Button>
                  </div>
                </div>
              </>
            </TabPanel>
            <TabPanel value={assetinfotab} index={3}>
              {/* Share Location */}
              <>
                <div className="vehicleinfo_info">
                  <span className="infospan">
                    Share Location: Send Vehicle Location Link by SMS or
                    Whatsapp
                  </span>
                </div>
                <div>
                  <div className="row fleet_body share_location mt_10">
                    <div className="col-md-12">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          viewlimit: "1",
                          expiryduration: "15",
                          phoneno: "",
                          remarks:
                            singleassetdata.driverName +
                            singleassetdata.id +
                            singleassetdata.location,
                        }}
                        validate={(values) => {
                          const errors = {};
                          if (values.viewlimit === "") {
                            errors.vlimit = "Field is Required";
                          }
                          if (values.expiryduration === "") {
                            errors.eduration = "Field is Required";
                          }
                          // if (values.phoneno === "") {
                          //   errors.phoneno = "Field is Required";
                          // }
                          if (states.sendMode === "") {
                            errors.mode = "Field is Required";
                          }
                          return errors;
                        }}
                        validationSchema={Schema}
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            const requestBody = {
                              emailId: "",
                              flag: _.toNumber(values.viewlimit),
                              linkExpDur: _.toNumber(values.expiryduration),
                              mobileNo: _.toString(values.phoneno),
                              remarks: values.remarks,
                              sendMode: _.toNumber(states.sendMode),
                              vehicleId: singleassetdata.vehicleId,
                            };
                            const config = {
                              headers: {
                                "Content-Type":
                                  "application/json; charset=utf-8",
                              },
                            };
                            axios
                              .post(
                                serverurl + "shareLocation",
                                requestBody,
                                config
                              )
                              .then(function (response) {
                                const value = response.data;
                                AlertStatus({
                                    ...alertstatus,
                                    sharelocationstatus: value,
                                  });
                                  AlertHide(true);
                                
                              });
                            setSubmitting(false);
                          }, 300);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit} className="mb_10">
                            <div className="row">
                              <div className="col-md-6 mb_15">
                                <TextField
                                  type="number"
                                  name="viewlimit"
                                  inputProps={{
                                    min: "1",
                                    max: "20",
                                  }}
                                  label="View Limit*"
                                  defaultValue={values.viewlimit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="col-md-12 p_0"
                                />

                                <span className="col-md-12 error-msg">
                                  {errors.vlimit}
                                </span>
                              </div>
                              <div className="col-md-6 mb_15">
                                <TextField
                                  type="number"
                                  name="expiryduration"
                                  inputProps={{
                                    min: "1",
                                    max: "99",
                                  }}
                                  label="Expiry Duration*"
                                  defaultValue={values.expiryduration}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="col-md-12 p_0"
                                />
                                <span className="col-md-12 error-msg">
                                  {errors.eduration}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FormControl className="col-md-12 p_0">
                                  <InputLabel htmlFor="age-simple">
                                    Mode
                                  </InputLabel>
                                  <Select
                                    value={states.sendMode}
                                    onChange={sharemodechange}
                                    inputProps={{
                                      name: "mode",
                                      id: "age-simple",
                                    }}
                                  >
                                    <MenuItem value="1">SMS</MenuItem>
                                  </Select>
                                  <span className="col-md-12 error-msg">
                                    {errors.mode}
                                  </span>
                                </FormControl>
                              </div>
                              <div className="col-md-6">
                                <FormControl className="col-md-12 p_0">
                                  <TextField
                                    id="standard-basic"
                                    name="phoneno"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText="Ex: 05********"
                                    type="text"
                                    label="Phone"
                                  />
                                  <span className="col-md-12 error-msg">
                                    {errors.phoneno}
                                  </span>
                                </FormControl>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <TextField
                                  label="Remarks"
                                  name="remarks"
                                  inputProps={{
                                    maxlength: CHARACTER_LIMIT,
                                  }}
                                  multiline
                                  defaultValue={values.remarks}
                                  helperText={
                                    values.remarks !== "" &&
                                      values.remarks !== undefined
                                      ? `${values.remarks.length}/${CHARACTER_LIMIT}`
                                      : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="col-md-12 p_0"
                                />
                              </div>
                            </div>

                            <div className="mt_15 share_location_btn">
                              <span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="quick-panel-tooltip">
                                      Send as SMS
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    <img
                                      alt="Toggle icon"
                                      src={SMSImg}
                                      className="mr_10"
                                      onClick={(e) => {
                                        handleSubmit(e);
                                      }}
                                    />
                                  </span>
                                </OverlayTrigger>

                                {/* <Button
                                  type="submit"
                                  disabled={isSubmitting}
                                  color="primary"
                                  variant="contained"
                                  className="mr_10"
                                  onClick={(e) => {
                                    handleSubmit(e);
                                  }}
                                >
                                  
                                </Button> */}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="quick-panel-tooltip">
                                      Send as Whatsapp
                                    </Tooltip>
                                  }
                                >
                                  <WhatsappShareButton
                                    url={
                                      "https://maps.google.com/?q=" +
                                      `${singleassetdata.latitude}` +
                                      "," +
                                      `${singleassetdata.longitude}`
                                    }
                                    title={
                                      "The current location of plate no " +
                                      `${singleassetdata.plateNo}`
                                    }
                                    separator=":"
                                    className="Demo__some-network__share-button"
                                  >
                                    <WhatsappIcon size={32} round />
                                  </WhatsappShareButton>
                                </OverlayTrigger>
                              </span>

                              <span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="quick-panel-tooltip">
                                      Navigate From
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href={
                                      "https://www.google.com/maps/dir/?api=1&origin=" +
                                      `${singleassetdata.latitude}` +
                                      "," +
                                      `${singleassetdata.longitude}` +
                                      "&travelmode=driving"
                                    }
                                    target="_blank"
                                    rel="Download File"
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="mr_10"
                                    >
                                      Navigate From
                                    </Button>
                                  </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="quick-panel-tooltip">
                                      Navigate To
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href={
                                      "https://www.google.com/maps/dir/?api=1&destination=" +
                                      `${singleassetdata.latitude}` +
                                      "," +
                                      `${singleassetdata.longitude}` +
                                      "&travelmode=driving"
                                    }
                                    target="_blank"
                                    rel="Download File"
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="mr_10"
                                    >
                                      Navigate To
                                    </Button>
                                  </a>
                                </OverlayTrigger>
                              </span>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </>
            </TabPanel>
          </SwipeableViews>
        </div>

         {/* Alert Sections */}
         {alertstatus !== undefined && alerthide === true ? (
          alertstatus !== "" && alertstatus.sharelocationstatus === 1 ? (
            <Alert
              className="sharelocation col-md-7 mt_15"
              onClose={() => alertshow(false)}
              variant="filled"
            >
              Location Shared Successfully
            </Alert>
          ) : alertstatus !== "" && alertstatus.sharelocationstatus === -2 ? (
            <Alert
              className="sharelocation col-md-7 mt_15"
              onClose={() => alertshow(false)}
              variant="filled"
              severity="error"
            >
              Insufficient SMS Credit, Sending Failed!
            </Alert>
          ) : (
            <Alert
              className="sharelocation col-md-7 mt_15"
              onClose={() => alertshow(false)}
              variant="filled"
              severity="error"
            >
              Please Check the Error's
            </Alert>
          )
        ) : (
          ""
        )}
        {/* Alert Sections */}
</>
     
  
  )
}
const mapStateToProps = ({ liveView }) => ({
  liveView,
});
export default connect(mapStateToProps)(SingleAssetInfo);