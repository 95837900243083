import React, { useEffect } from 'react'
import {
    Button,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Snackbar
  } from "@material-ui/core";
  import axios from "axios";
  import _ from "lodash";
  import {
    serverurl,
  } from "../../router/config";
  import { Formik } from "formik";
  import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
  function Alertinfo(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  // Styles
  const useStyles = makeStyles((theme) => ({

    paper: {
      position: "absolute",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: "20px",
    },
    
  }));

function AddrightclickPOI(props) {
const{poilat,poilng,handleClose} = props
    
    const [getpoigroups, GetPoiGroups] = React.useState();
    const [getpoicategories, GetPoiCategories] = React.useState();
    const [POIResponse, setPOIResponse] = React.useState("");
    const [poiopen, setPOIOpen] = React.useState(false);
    const [poistate, setPoistate] = React.useState({
        poigroup: "",
        poicategory: "",
      });

     
      const classes = useStyles();
      const [modalStyle] = React.useState(getModalStyle);

      function rand() {
        return Math.round(Math.random() * 20) - 10;
      }
      function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();
      
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
      }

useEffect(() => {
    console.log("inside AddPOI", props)
    addrightclickpoi()
},[])

       // POI Group Change
    function poigroupchange(event) {
        const val = event.target.value;
        setPoistate({ ...poistate, poigroup: val });
      }
      // POI Group Change
    
      // POI Category Change
      function poicategorychange(event) {
        const val = event.target.value;
        setPoistate({ ...poistate, poicategory: val });
      }
      // POI Category Change

      const handleinfoClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }    
        setPOIOpen(false);
      };

function addrightclickpoi() {
    const poigroupsurl = "GetPoiGroups";
    axios.get(serverurl + poigroupsurl).then(function (response) {
      GetPoiGroups(response.data);
    });
    const poicategoriesurl = "GetPoiCategories";
    axios.get(serverurl + poicategoriesurl).then(function (response) {
      GetPoiCategories(response.data);
    });
  }
 
return(
<div>
<div style={modalStyle} className={classes.paper}>
        <h4 id="nearby_vehicle-title">Add POI</h4>
        <div id="nearby_vehicle">
          <div className=" col-md-12 p_0">
            <div className="row">
              <Formik
                enableReinitialize
                initialValues={{
                  name: "",
                  poiid: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = "POI Name is Required";
                  }
                  if (values.poiid === "") {
                    errors.poiid = "POI ID is Required";
                  }
  
                  if (!poistate.poigroup) {
                    errors.poigroup = "POI Group is Required";
                  }
                  if (!poistate.poicategory) {
                    errors.poicategory = "POI Category is Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  var poilocation =
                    poilng !== "" &&
                      poilat !== "" &&
                      poilng !== undefined &&
                      poilat !== undefined
                      ? poilng + " " + poilat
                      : "";
                 
  
                  setTimeout(() => {
                    const requestBody = {
                      buildingName: "",
                      displayIcon: "Blue.png",
                      doorNumber: "",
                      geom: "",
                      geomText: _.toString(poilocation),
                      grl: "",
                      mobile: "",
                      poiCategoryId: _.toString(poistate.poicategory),
                      poiCode: _.toString(values.poiid),
                      poiGroupId: _.toString(poistate.poigroup),
                      poiId: "-1",
                      poiName: values.name,
                      poiTypeId: _.toString("1"),
                      poititles: [],
                      radius: "",
                      remarks: "",
                      telephone: "",
                      territoryId: "",
                    };
                    const config = {
                      headers: {
                        "Content-Type": "application/json; charset=utf-8",
                      },
                    };
                    axios
                      .post(serverurl + "CreatePoi", requestBody, config)
                      .then(function (response) {
                        const value = response.data;
                        setPOIResponse(value);
                      setPOIOpen(true);
                      });
                    setSubmitting(false);
                  }, 300);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form className="col-md-12" onSubmit={handleSubmit}>
                    <div className="mb_15">
                      <TextField
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        id="standard-basic"
                        label="POI Name*"
                        className="col-md-12"
                      />
                      <span className="error-msg">
                        {errors.name && touched.name && errors.name}
                      </span>
                    </div>
                    <div className="mb_15">
                      <TextField
                        type="number"
                        name="poiid"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.poiid}
                        id="standard-basic"
                        label="POI ID*"
                        className="col-md-12"
                      />
                      <span className="error-msg">
                        {errors.poiid && touched.poiid && errors.poiid}
                      </span>
                    </div>
                    <div className="mb_15">
                      <FormControl className="col-md-12 p_0">
                        <InputLabel id="demo-simple-select-label">
                          Select POI group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={poistate.poigroup}
                          onChange={poigroupchange}
                        >
                          {getpoigroups !== undefined && getpoigroups !== ""
                            ? getpoigroups.map((item, index) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                            : ""}
                        </Select>
                      </FormControl>
                      <span className="error-msg">{errors.poigroup}</span>
                    </div>
                    <div className="mb_15">
                      <FormControl className="col-md-12 p_0">
                        <InputLabel id="demo-simple-select-label">
                          Select POI Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={poistate.poicategory}
                          onChange={poicategorychange}
                        >
                          {getpoicategories !== undefined &&
                            getpoicategories !== ""
                            ? getpoicategories.map((item, index) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                            : ""}
                        </Select>
                      </FormControl>
                      <span className="error-msg">{errors.poicategory}</span>
                    </div>
                    <div className="flex_div justify_sb">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        color="secondary"
                        className="ps_btn"
                      >
                        Add POI
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="ps_btn"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="row">
              {POIResponse !== "" &&
                POIResponse !== undefined &&
                POIResponse !== null ? (
                POIResponse !== -1 || POIResponse > 0 ? (
                  <Snackbar
                    open={poiopen}
                    autoHideDuration={3000}
                    onClose={handleinfoClose}
                    className="col-md-12"
                  >
                    <Alertinfo onClose={handleinfoClose} severity="success">
                      POI Saved Successfully!
                    </Alertinfo>
                  </Snackbar>
                ) : (
                  <Snackbar
                    open={poiopen}
                    autoHideDuration={3000}
                    onClose={handleinfoClose}
                    className="col-md-12"
                  >
                    <Alertinfo onClose={handleinfoClose} severity="error">
                      Saving Failed.
                    </Alertinfo>
                  </Snackbar>
                )
              ) : (
                ""
              )}
            </div>
             
            </div>
          </div>
        </div>
      </div>
      

</div>

)

}
export default  AddrightclickPOI;