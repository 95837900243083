// Login page with mobile app sections

import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../infofleet";
import "../../../infofleet/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";

export default function AuthPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-8.jpg")})`,
              }}
            >
              <div className="fcc kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo.svg")}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="text-center kt-login__title">
                    Welcome to InfoFleet
                  </h3>
                  <h4 className="text-center kt-login__subtitle">
                    What Gets Measured, Gets Improved...!
                  </h4>
                  <div>
                    <h3 className="text-center mobileapp_title mb_20">
                      InfoFleet Mobile App is available on:
                    </h3>
                    <div className="row login_app_area">
                      <div className="col-md-6">
                        <a
                          className="fcc mb_20"
                          href="https://apps.apple.com/bt/app/infofleet-mobile/id1097711730"
                          target="_blank"
                        >
                          <img
                            src={toAbsoluteUrl(
                              "/media/misc/appstore-badge.png"
                            )}
                            alt="App Store Icon"
                            className="appimg"
                          />
                        </a>
                        <a
                          className="fcc mb_20"
                          href="https://apps.apple.com/bt/app/infofleet-mobile/id1097711730"
                          target="_blank"
                        >
                          <img
                            src={toAbsoluteUrl("/media/misc/QR_IOS.png")}
                            alt="App Store Icon"
                            className="QRimg"
                          />
                        </a>
                      </div>
                      <div className="col-md-6">
                        <a
                          className="fcc mb_20"
                          href="https://play.google.com/store/apps/details?id=com.noms.infofleet&hl=en"
                          target="_blank"
                        >
                          <img
                            src={toAbsoluteUrl(
                              "/media/misc/google-play-badge.png"
                            )}
                            alt="Play Store Icon"
                            className="playimg"
                          />
                        </a>
                        <a
                          className="fcc mb_20"
                          href="https://play.google.com/store/apps/details?id=com.noms.infofleet&hl=en"
                          target="_blank"
                        >
                          <img
                            src={toAbsoluteUrl("/media/misc/QR_Andriod.png")}
                            alt="App Store Icon"
                            className="QRimg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">&copy; Infofleet</div>
                  <div className="kt-login__menu">
                    <Link to="/terms" className="kt-link">
                      Privacy
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Legal
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/login" component={Login} />
                {/* <Route path="/auth/registration" component={Registration} /> */}
                {/* <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                /> */}
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
