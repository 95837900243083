import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { infofleet } from "../../infofleet";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: infofleet.i18n.reducer,
  builder: infofleet.builder.reducer,
  functionaltree: infofleet.functionaltree.reducer,
  quickoptions: infofleet.quickoptions.reducer,
  // sidebardashboard: infofleet.sidebardashboard.reducer,
  liveView: infofleet.liveView.reducer,
  maplayer: infofleet.maplayer.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    infofleet.functionaltree.saga(),
    infofleet.quickoptions.saga(),
    // infofleet.sidebardashboard.saga(),
  ]);
}
