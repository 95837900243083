// import { put, takeLatest } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../app/router/RouterHelpers";

export const actionTypes = {
  MapLayer: "MAP_LAYER",
  MapZoomView:"MAP_ZOOM_VIEW",
  ClusterView: "CLUSTER_VIEW",
  Resetdata: "[Logout] Action",
};

const initialState = {
  MapLayer: "informap",
  MapZoomView:"default",
  ClusterView: true,
};

export const reducer = persistReducer(
  {
    storage,
    key: "build-demo2",
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.MapLayer:
        return { ...state, MapLayer: payload };
      case actionTypes.MapZoomView:
        return { ...state, MapZoomView: payload };
      case actionTypes.ClusterView:
        return { ...state, ClusterView: payload };
      case actionTypes.Resetdata: {
        routerHelpers.forgotLastLocation();
        return initialState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  MapLayer: (payload) => ({
    payload,
    type: actionTypes.MapLayer,
  }),
  MapZoomView: (payload) => ({
    payload,
    type: actionTypes.MapZoomView,
  }),
  ClusterView: (payload) => ({
    payload,
    type: actionTypes.ClusterView,
  }),
  Resetdata: () => ({ type: actionTypes.Resetdata }),
};
