// Area Names Static Data

export var staticareanames = [
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Al Dhafra",
      add6: null,
      area_cx: 52.98025448,
      area_cy: 23.86510638,
      cntry_id: 9,
      id: 100000001,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Dubai",
      add6: null,
      area_cx: 55.25000184,
      area_cy: 25.06232424,
      cntry_id: 9,
      id: 100000002,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Al Ain",
      add6: null,
      area_cx: 55.6024783,
      area_cy: 24.25078618,
      cntry_id: 9,
      id: 100000003,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Abu Dhabi",
      add6: null,
      area_cx: 54.59110526,
      area_cy: 24.34364583,
      cntry_id: 9,
      id: 100000004,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Sharjah",
      add6: null,
      area_cx: 55.45241006,
      area_cy: 25.3240937,
      cntry_id: 9,
      id: 100000005,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Ajman",
      add6: null,
      area_cx: 55.52704096,
      area_cy: 25.40365073,
      cntry_id: 9,
      id: 100000006,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Umm Al Quwain",
      add6: null,
      area_cx: 55.60928786,
      area_cy: 25.49508027,
      cntry_id: 9,
      id: 100000007,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Ras Al Khaimah",
      add6: null,
      area_cx: 55.91598064,
      area_cy: 25.67586516,
      cntry_id: 9,
      id: 100000008,
    },
    {
      add1: "UAE",
      add2: "static_city",
      add3: null,
      add4: "UAE",
      add5: "Fujairah",
      add6: null,
      area_cx: 56.33702023,
      area_cy: 25.35882503,
      cntry_id: 9,
      id: 100000009,
    },
  ];
  
  // Area Names Static Data