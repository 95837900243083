// Auth Layout Sections
import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../infofleet";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }
  render() {
    const { hasAuthToken } = this.props;
    const clearinterval_id_notification = localStorage.getItem(
      "notification_interval"
    );
    clearInterval(clearinterval_id_notification);
    localStorage.removeItem("notification_interval");
    const clearinterval_id_LiveView = localStorage.getItem("LiveView_interval");
    clearInterval(clearinterval_id_LiveView);
    localStorage.removeItem("LiveView_interval");
    localStorage.removeItem("ServiceAlertStatus_vehicleIds")
    localStorage.removeItem("ServiceHistory")
    localStorage.removeItem("alertdata")
    localStorage.removeItem("paxList")
    localStorage.removeItem("selectedpaxtype")
    localStorage.removeItem("woxerrorfilter")
    localStorage.removeItem("paxwoxhistoryfilter")
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}
export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
