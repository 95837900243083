import React, { useEffect } from 'react'
import { assetDropdown } from "./Dropdowns"; 
import axios from 'axios';
import {
    CheckBoxSelection,
    Inject,
    MultiSelectComponent,
  } from "@syncfusion/ej2-react-dropdowns";
  import _ from "lodash";
  import * as liveViewAction from "../../../infofleet/ducks/liveView";
  import { connect, useDispatch } from "react-redux";
  import { serverurl } from "../../router/config";

function SelectDriverDropdown(props){
    const { handleAssetChange} = props

    const [assetDropDown, setAssetDropDown] = React.useState([]);
    const dispatch = useDispatch();
    useEffect(()=>{
        getAssetData();
    },[])

    const getAssetData = async () => {
        const res = await assetDropdown();
        setAssetDropDown(res.data.items);
      };
    
// Multiselect Box
  const assetarray =
  assetDropDown !== "" && assetDropDown !== undefined
    ? assetDropDown.filter(function (obj) {
      return obj.type === "child";
    })
    : "";

// Asset Chnage Function
const assetchange = async (event) => {
    var filter = event.value.length !== 0 ? true : false
    var vehicleIds = event.value
  

  var device_id = event.value;
  const SERVICE_URL = serverurl + "getLiveStatusData";
  //console.log("hit 4");
  const requestBody = {
    vehicleIds: _.toString(device_id),
  };
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  const response = await axios.post(SERVICE_URL, requestBody, config);
  dispatch(liveViewAction.actions.filterData(response.data[0].items));
  handleAssetChange(filter,vehicleIds)
};
// Asset Chnage Function

const asset_fields = {
  groupBy: "groupname",
  text: "name",
  value: "vehicleId",
};


    return(
        <MultiSelectComponent
        id="mtselement"
        popupHeight="500px"
        popupWidth="350px"
        fields={asset_fields}
        dataSource={assetarray}
        placeholder="Select by ID/Plate/Driver"
        mode="CheckBox"
        enableGroupCheckBox="true"
        filterType="Contains"
        allowFiltering="true"
        change={assetchange}
        changeOnBlur={false}
        showSelectAll="true"
        cssClass="col-md-10 p_0 mb_0 mt_5"
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>

    )
}
export default SelectDriverDropdown;