import React, { useEffect } from 'react';
import { TextField} from '@material-ui/core';

export default function CustomTextField(props) {
    const { type,name,value, onChange,label,className,...other } = props;
    return (
        <TextField
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            label={label}
            className="col-md-10 p_0"
            {...other}
        />
    )
}