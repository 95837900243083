import React from 'react'
import {
    Checkbox,
    Grid
} from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from '../../../infofleet/utils/utils'
function TopBarFilter(props) {
    const { livepanelfilter, livepanelclick, filterCount, locationfilter, fullscreen, vehicletypeBike,vehicletypeOthers } = props
    return (
        <div className={fullscreen === 1 ? "fullscreen_status_icons_area" : "liveviewicons"}>



            {/* Move Count Filter */}
            <Grid item className="liveview_topbar_filtersection">
                {/* {movecount !== "" &&
              movecount !== null &&
              movecount !== undefined ? ( */}
                <OverlayTrigger
                    placement={fullscreen === 1 ? "top" : "bottom"}
                    overlay={
                        <Tooltip id="quick-panel-tooltip">
                            Moving
                        </Tooltip>
                    }
                >
                    <div className="liveviewicons_section lv_move">
                        <img
                            alt=""
                            src={fullscreen === 1 ? toAbsoluteUrl(
                                "/media/icons/LiveViewCar/TVview/" +
                                `${  vehicletypeBike.length > 0 && vehicletypeOthers === 0
                                    ? "tv_moving_bike.svg"
                                    : "tv_moving.svg"
                                }`
                            ) : toAbsoluteUrl(
                                "/media/icons/LiveViewCar/moving_car1.svg"
                            )}
                        />{" "}
                        <span className="topbar_count">
                            {filterCount.movecount === undefined ? "0" : filterCount.movecount}{" "}
                        </span>
                        <Checkbox
                            name="Moving"
                            checked={livepanelfilter.Moving}
                            onChange={e => livepanelclick(e, "Moving")}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            disabled={
                                locationfilter === true
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </OverlayTrigger>
                {/* // ) : (
              //   ""
              // )} */}
            </Grid>
            {/* Move Count Filter */}

            {/* Stop Count Filter */}
            <Grid item className="liveview_topbar_filtersection">
                {/* {Stoppedcount !== "" &&
              Stoppedcount !== null &&
              Stoppedcount !== undefined ? ( */}
                <OverlayTrigger
                    placement={fullscreen === 1 ? "top" : "bottom"}
                    overlay={
                        <Tooltip id="quick-panel-tooltip">
                            Stopped
                        </Tooltip>
                    }
                >
                    <div className="liveviewicons_section  lv_stop">
                        <img
                            alt=""
                            src={fullscreen === 1 ? toAbsoluteUrl(
                                "/media/icons/LiveViewCar/TVview/" +
                                `${  vehicletypeBike.length > 0 && vehicletypeOthers === 0
                                    ? "tv_stopped_bike.svg"
                                    : "tv_stopped.svg"
                                }`
                            ) : toAbsoluteUrl(
                                "/media/icons/LiveViewCar/stopped_car1.svg"
                            )}
                        />{" "}
                        <span className="topbar_count">
                            {filterCount.Stoppedcount === undefined
                                ? "0"
                                : filterCount.Stoppedcount}{" "}
                        </span>
                        <Checkbox
                            name="Stopped"
                            checked={livepanelfilter.Stopped}
                            onChange={e => livepanelclick(e, "Stopped")}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            disabled={
                                locationfilter === true
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </OverlayTrigger>
                {/* ) : (
                ""
              )} */}
            </Grid>
            {/* Stop Count Filter */}

            {/* Parked Count Filter */}
            <Grid item className="liveview_topbar_filtersection">
                {/* {parkcount !== "" &&
              parkcount !== null &&
              parkcount !== undefined ? ( */}
                <OverlayTrigger
                    placement={fullscreen === 1 ? "top" : "bottom"}
                    overlay={
                        <Tooltip id="quick-panel-tooltip">
                            Parked
                        </Tooltip>
                    }
                >
                    <div className="liveviewicons_section lv_park">
                        <img
                            alt=""
                            src={fullscreen === 1 ? toAbsoluteUrl(
                                "/media/icons/LiveViewCar/TVview/" +
                                `${  vehicletypeBike.length > 0 && vehicletypeOthers === 0
                                    ? "tv_parked_bike.svg"
                                    : "tv_parked.svg"
                                }`
                            ) : toAbsoluteUrl(
                                "/media/icons/LiveViewCar/parked_car1.svg"
                            )}
                        />{" "}
                        <span className="topbar_count">
                            {filterCount.parkcount === undefined ? "0" : filterCount.parkcount}{" "}
                        </span>
                        <Checkbox
                            name="Parked"
                            checked={livepanelfilter.Parked}
                            onChange={e => livepanelclick(e, "Parked")}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            disabled={
                                locationfilter === true
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </OverlayTrigger>
                {/* ) : (
                ""
              )} */}
            </Grid>
            {/* Parked Count Filter */}

            {/* No Signal Count Filter */}
            <Grid item className="liveview_topbar_filtersection">
                {/* {noScount !== "" &&
              noScount !== null &&
              noScount !== undefined ? ( */}
                <OverlayTrigger
                    placement={fullscreen === 1 ? "top" : "bottom"}
                    overlay={
                        <Tooltip id="quick-panel-tooltip">
                            No Signal
                        </Tooltip>
                    }
                >
                    <div className="liveviewicons_section lv_nosignal">
                        <img
                            alt=""
                            src={fullscreen === 1 ? toAbsoluteUrl(
                                "/media/icons/LiveViewCar/TVview/" +
                                `${  vehicletypeBike.length > 0 && vehicletypeOthers === 0
                                    ? "tv_nosignal_bike.svg"
                                    : "tv_nosignal.svg"
                                }`
                            ) : toAbsoluteUrl(
                                "/media/icons/LiveViewCar/nosignal_car1.svg"
                            )}
                        />{" "}
                        <span className="topbar_count">
                            {filterCount.noScount === undefined ? "0" : filterCount.noScount}{" "}
                        </span>
                        <Checkbox
                            name="No Signal"
                            checked={livepanelfilter.NoSignal}
                            onChange={e => livepanelclick(e, "No Signal")}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            disabled={
                                locationfilter === true
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </OverlayTrigger>
                {/* ) : (
                ""
              )} */}
            </Grid>
            {/* No Signal Count Filter */}

            {/* Engine ON Count Filter */}
            <Grid item className="liveview_topbar_filtersection">
                {/* {Engineoncount !== "" &&
              Engineoncount !== null &&
              Engineoncount !== undefined ? ( */}
                <OverlayTrigger
                    placement={fullscreen === 1 ? "top" : "bottom"}
                    overlay={
                        <Tooltip id="quick-panel-tooltip">
                            Idling Eng ON
                        </Tooltip>
                    }
                >
                    <div className="liveviewicons_section lv_engineOn">
                        <img
                            alt=""
                            src={fullscreen === 1 ? toAbsoluteUrl(
                                "/media/icons/LiveViewCar/TVview/" +
                                `${  vehicletypeBike.length > 0 && vehicletypeOthers === 0
                                    ? "tv_engine_on_bike.svg"
                                    : "tv_engine.svg"
                                }`
                            ) : toAbsoluteUrl(
                                "/media/icons/LiveViewCar/engine_on.svg"
                            )}
                        />{" "}
                        <span className="topbar_count">
                            {filterCount.Engineoncount === undefined
                                ? "0"
                                : filterCount.Engineoncount}{" "}
                        </span>
                        <Checkbox
                            name="Engine ON"
                            checked={livepanelfilter.Engineon}
                            onChange={e => livepanelclick(e, "Engine ON")}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            disabled={
                                locationfilter === true
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </OverlayTrigger>
                {/* ) : (
                ""
              )} */}

                {/* Engine ON Count Filter */}


            </Grid>

        </div>
    )

}
export default TopBarFilter;