import React, { useState } from 'react'
import useTable from '../controls/useTable'
import { Paper, makeStyles, TextField,Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from '@material-ui/core';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    table_section: {
        backgroundColor: "white",
        overflow: "auto",
        maxHeight: "325px",
       

    },
    list: {
        width: 250,
    },
    searchField:{
        color:"black"
    }
}))


const headCells = [


  { id: 'No', label: 'No' },
  { id: 'vUniqueId', label: 'Asset ID' },
  { id: 'plateNo', label: 'Plate No' },
  { id: 'driverName', label: 'Driver' },
  { id: 'groupName', label: 'Group' },
  { id: 'vmode', label: 'Status' },
  { id: 'dateTime', label: 'DateTime' ,disableSorting:true},
  { id: 'location', label: 'Location' }
]

function ListViewGridTable(props) {
  const { records, sidebarselectedAsset, singleassetdata } = props
  const classes = useStyles();
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn)

  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
        fn: items => {
            if (target.value == "")
                return items;
            else
                return items.filter(x => x.vUniqueId.toLowerCase().includes(target.value))
        }
    })
}

  return (
    <>


<div id="useGrid_table" className='use_table'>
                    <div className={classes.table_section}>

                    <Toolbar>
                        <Grid item xs={8} sm={6} className="grid_searchbar">
                            <TextField
                                variant="standard"
                                label="Search by Asset ID"
                                onChange={handleSearch}
                                className="searchbar_label"
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start" id="tableSearchIcon">
                                        <Search />
                                    </InputAdornment>)
                                }}

                            />
                        </Grid>

                    </Toolbar>
        <TblContainer component={Paper}>
          <TblHead />

          <TableBody>
            {

recordsAfterPagingAndSorting().map((listviewdata, index) => (
                <TableRow
                  key={index}
                  onClick={() => sidebarselectedAsset(listviewdata)}
                  hover
                  selected={
                    singleassetdata.id === listviewdata.id
                      ? true
                      : false
                  }
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {listviewdata.vUniqueId}
                  </TableCell>
                  <TableCell align="center">
                    {listviewdata.plateNo}
                  </TableCell>
                  <TableCell align="center">
                    {listviewdata.driverName}
                  </TableCell>
                  <TableCell align="center">
                    {listviewdata.groupName}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={
                      listviewdata.vmode === "Moving"
                        ? "Moving_text"
                        : listviewdata.vmode === "Parked"
                          ? "Parked_text"
                          : listviewdata.vmode === "Stopped"
                            ? "Stopped_text"
                            : listviewdata.vmode === "Idling Engine ON"
                              ? "Engineon_text"
                              : listviewdata.vmode === "No Signal"
                                ? "Nosignal_text"
                                : ""
                    }
                  >
                    {listviewdata.vmode}
                  </TableCell>
                  <TableCell align="center">
                    {listviewdata.dateTime}

                  </TableCell>
                  <TableCell align="center">
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>{listviewdata.location}</Tooltip>
                        }
                      >
                        <div>
                          {listviewdata.location !== "" &&
                            listviewdata.location !== undefined &&
                            listviewdata.location !== null
                            ? listviewdata.location.length > 35
                              ? listviewdata.location.slice(
                                listviewdata.location,
                                "35"
                              ) + "..."
                              : listviewdata.location
                            : ""}
                        </div>
                      </OverlayTrigger>
                    </>
                  </TableCell>
                </TableRow>
              )

              )

            }


          </TableBody>

        </TblContainer>
        <TblPagination />
        {/*<BasicTable/>*/}


      </div>
</div>

    </>



  )
}
export default ListViewGridTable;