import React from "react";
import clsx from "clsx";
import { connect, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { infofleet, toAbsoluteUrl } from "../../../infofleet";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MapZoomIcon from "../../../assets/infofleet/mapzoom.png";
import * as maplayerAction from "../../../infofleet/ducks/maplayer";

function MapZoomLock({ maplayer }) {
  const dispatch = useDispatch();
  const mapzoomview = maplayer.MapZoomView;
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-panel-tooltip"><span className="font-bold">Zoom Lock:</span> Fix map zoom view to disable automatic zoom after every map refresh</Tooltip>}
    >
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className="kt-header__topbar-icon
              kt-header__topbar-icon--warning"
          >
            <span className="kt-svg-icon kt-svg-icon--warning">
              <img
                style={{ width: "24px" }}
                src={MapZoomIcon}
                alt="Maps"
                className="kt-svg-icon kt-svg-icon--primary"
              />
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="kt-nav kt-margin-t-10 kt-margin-b-10">
            <li key="1" className={clsx("kt-nav__item")}>
              <span
                onClick={() => {
                  dispatch(maplayerAction.actions.MapZoomView("highway"));
                }}
                className={clsx("kt-nav__link")}
              >
                <span
                  className={`${
                    mapzoomview === "highway" ? "zoomlock_active" : ""
                  }  kt-nav__link-text `}
                >
                  Highway View
                </span>
              </span>
            </li>
            <li key="2" className={clsx("kt-nav__item")}>
              <span
                onClick={() => {
                  dispatch(maplayerAction.actions.MapZoomView("street"));
                }}
                className={clsx("kt-nav__link")}
              >
                <span
                  className={`${
                    mapzoomview === "street" ? "zoomlock_active" : ""
                  }  kt-nav__link-text `}
                >
                  Street View
                </span>
              </span>
            </li>
            <li key="3" className={clsx("kt-nav__item")}>
              <span
                onClick={() => {
                  dispatch(maplayerAction.actions.MapZoomView("building"));
                }}
                className={clsx("kt-nav__link")}
              >
                <span
                  className={`${
                    mapzoomview === "building" ? "zoomlock_active" : ""
                  }  kt-nav__link-text `}
                >
                  Building View
                </span>
              </span>
            </li>
            <li key="4" className={clsx("kt-nav__item")}>
              <span
                onClick={() => {
                  dispatch(maplayerAction.actions.MapZoomView("default"));
                }}
                className={clsx("kt-nav__link")}
              >
                <span
                  className={`${
                    mapzoomview === "default" ? "zoomlock_active" : ""
                  }  kt-nav__link-text `}
                >
                  Default View
                </span>
              </span>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger>
  );
}

const mapStateToProps = ({ maplayer }) => ({ maplayer });

export default connect(mapStateToProps, { maplayerAction })(MapZoomLock);
