import React from "react";
// import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
import Feedback from "../../../app/partials/layout/Feedback";
import MapActionsPanel from "../../../app/partials/layout/MapActionsPanel";
import NotificationPanelToggler from "./NotificationPanelToggle";
import DashboardToggler from "./DashboardPanelToggle";
// import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import MapZoomLock from "../../../app/partials/layout/MapZoomLock";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import ClusterIcon from "../../../assets/infofleet/cluster.png";
import { Switch } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { connect, useDispatch } from "react-redux";
import * as maplayerAction from "../../../infofleet/ducks/maplayer";

function Topbar({ maplayer }) {
  const dispatch = useDispatch();
  const clusterview = maplayer.ClusterView;

  const clusterChange = (event) => {
    dispatch(maplayerAction.actions.ClusterView(event.target.checked));
  };

  return (
    <div className="kt-header__topbar">
      {/* <SearchDropdown useSVG="true" /> */}

      {/* <UserNotifications
          bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          pulse="true"
          pulseLight="false"
          skin="dark"
          iconType=""
          type="success"
          useSVG="true"
          dot="false"
        /> */}
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-panel-tooltip"><span className="font-bold">Cluster View:</span> Vehicle Icons are clustered showing the count. Turn ON, if you have large number of vehicles.</Tooltip>}
      >
        <div className="kt-header__topbar-item">
          <div className="cluster_div kt-header__topbar-icon">
            <img style={{ width: "24px" }} src={ClusterIcon} alt="Cluster" />
            <Switch
              checked={
                clusterview !== "" &&
                clusterview !== undefined &&
                clusterview !== null
                  ? clusterview
                  : false
              }
              onChange={clusterChange}
              color="primary"
              name="clustercheck"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
      </OverlayTrigger>

      <MapZoomLock />

      <MapActionsPanel
        bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
        skin="dark"
        iconType=""
        useSVG="true"
        gridNavSkin="light"
      />

      <NotificationPanelToggler />
      {/* <DashboardToggler /> */}

      <Feedback
        iconType=""
        useSVG="true"
        bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
      />

      <UserProfile showAvatar={true} showHi={true} showBadge={false} />
    </div>
  );
}

const mapStateToProps = ({ maplayer }) => ({
  maplayer,
});
export default connect(mapStateToProps, { maplayerAction })(Topbar);
