import React, { useEffect } from 'react'
import axios from 'axios';
import { Button } from "@material-ui/core";
import _ from "lodash";
import { serverurl } from "../../router/config";
import { staticareanames } from "../MapComponents/AreaNames";
import Controls from '../FormControls/Controls';

function SearchbyAreaname(props) {
  const { locationChange, location_submit, location_close, locationfilter, filter, userselect, areatargetsearch } = props

  const [areanamesbyservice, GetAreaNames] = React.useState("");
  //const [areatargetsearch, setAreatargetsearch] = React.useState("");

  useEffect(() => {

    const GetAreanames = async () => {
      await axios.get(serverurl + "getAreaKeywords").then(function (response) {
        GetAreaNames(response.data);
      });
    }
    GetAreanames();
  }, [])

  var areanames =
    areanamesbyservice !== "" && areanamesbyservice !== undefined
      ? _.concat(staticareanames, areanamesbyservice)
      : "";

  var autofocusfields = { value: "add5" };

  return (
    <div className="row flex_div flex_center" >
      <div className="col-sm-10 col-md-10 p_0 auto_suggest_div">

        {areanames !== "" &&
          areanames !== undefined &&
          areanames !== null ? (
          <Controls.AutoComplete
            id="areanames"
            dataSource={areanames}
            fields={autofocusfields}
            placeholder="Enter City/Area Name"
            select={userselect}
            minLength={3}
            value={areatargetsearch}
            cssClass="areaname_search"
          />
        ) : (
          <Controls.CustomTextField
            type="text"
            name="location"
            value={areatargetsearch}
            onChange={locationChange}
            label="Enter City/Area Name"
            disabled={
              filter === true &&
                locationfilter === false
                ? true
                : false
            }
          />
        )}
      </div>
      <div className="col-sm-2 col-md-2 p_0">
        {locationfilter === false ? (
          <Button
            onClick={location_submit}
            className="liveview_search"
            disabled={
              filter === true &&
                locationfilter === false
                ? true
                : false
            }
          >
            <i className="la la-search la-2x" />
          </Button>
        ) : locationfilter === true ? (
          <Button
            onClick={location_close}
            className="liveview_search liveview_close"
          >
            <i className="la la-close la-2x" />
          </Button>
        ) : (
          ""
        )}
      </div>

    </div>

  )
}
export default SearchbyAreaname;