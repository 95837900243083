// Function to get the options from the service level

import axios from "axios";
import { serverurl } from "../../router/config";

// Getting Asset Data
export const assetDropdown = async () => {
  return await axios.get(serverurl + "getTreeViewList?vehicleIds=-1");
};

// Getting Driver Data
export const driverDropdown = async () => {
  return await axios.get(serverurl + "getDriverTreeViewForReport");
};

// Getting Panel Search Asset Data
export const psasset = async () => {
  return await axios.get(serverurl + "populateHistoryAssets?target_search=-1");
};

// Getting Panel Search Driver Data
export const psdrivers = async () => {
  return await axios.get(serverurl + "populateDrivers?target_search=-1");
};
