import React, { lazy, useState } from 'react'
import MarkerIcon from './MarkerIcon';
import {
  Marker,
  Popup,
  Tooltip as MapToolTip,
} from "react-leaflet";
import CustomMapTooltip from "./CustomMapTooltip";
import {  Modal} from "@material-ui/core";
const ShareLocation = lazy(() => import("./ShareLocation"));

function CustomMarker(props){
    const {position,item,lat,lon} = props
    const[open,setOpen] = useState(false)
    const [states, setStates] = React.useState({

      lat: 24.9241,
      lng: 54.8478,
      zoom: 8,
      poiName: "",
      poiCode: "",
      sharelocation_lat: "",
      sharelocation_lon: "",
  
    });

      //Sharelocation model open and close
  const handleOpen = (lat, lon, poiName, poiCode) => {
    console.log("handle open :", lat, lon, poiCode, poiName)
    setStates({ ...states, poiName: poiName, poiCode: poiCode, sharelocation_lat: lat, sharelocation_lon: lon })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };
  //Sharelocation model open and close


    return(
      <>
        <div>
     <Marker
             position={position}
             icon={
               MarkerIcon(item)
             }
             onMouseOver={(e) => {
                e.target.openPopup();
              }}
              onMouseOut={(e) => {
                e.target.closePopup();
              }}
              onClick={() =>
                handleOpen(lat,lon, item.poiName, item.poiCode)

              }
 
           >
             <Popup offset={[0, -10]}>
               <CustomMapTooltip
                 poiname={item.poiName}
                 poicode={item.poiCode}
                 poigroup={item.poiGroupName}
               />
             </Popup>
           </Marker>
        </div>

          {/*share location modal */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="share_location-title"
        aria-describedby="share_location"
      >

        <ShareLocation 
        lat={states.sharelocation_lat} 
        lon={states.sharelocation_lon} 
        poiName={states.poiName} 
        poiCode={states.poiCode}
        handleClose={handleClose}
         />
      </Modal>
        </>
    )
}
export default CustomMarker;