// Loader - This loader was used in all form submit and page load
import React from "react";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function Loader(params) {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={params.show}>
        <CircularProgress color="inherit" />
        This may take a short while, Thank you for your patience!
      </Backdrop>
    </>
  );
}
