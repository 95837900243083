// POI Icons: POI have different Categories. So this file specifies the POI Icons based on the categories
import React from "react";
import { toAbsoluteUrl } from "../../../infofleet/utils/utils";
import L from "leaflet";

export const FlagIcon = new L.Icon({
  iconUrl: toAbsoluteUrl(
    "/media/icons/markers/Map-Marker-Flag-1-Right-Pink-icon.png"
  ),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const BlueIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/Blue.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const BankIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/bank.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const BuildingIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/building.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const ConstructionIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/construction.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const DarkGreenIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/DarkGreen.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const EmbassyIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/embassy.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const GoldIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/gold.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});


export const HomeIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/home.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const HospitalIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/hospital.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const HotelIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/hotel.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const LightGreenIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/LightGreen.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const OfficeIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/office.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const PurpleIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/Purple.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const RedIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/red.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const ResidentialIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/residential.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const RestaurantIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/restaurant.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const SchoolIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/school.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const SeaportIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/seaport.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const ShowroomIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/showroom.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});

export const StoreIcon = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/icons/markers/store.png"),
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 30),
  iconAnchor: [10, 25],
});
