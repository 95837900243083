/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { serverurl } from "../../router/config";
import axios from "axios";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../infofleet";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

function UserProfile(props) {
  useEffect(() => {
    const userurl = "getLoggedUserprofile";
    axios.get(serverurl + userurl).then(function(response) {
      LoggeduserDetail(response.data[0]);
    });
  }, []);
  const [loggeduserdetail, LoggeduserDetail] = React.useState("");

  const { user, showHi, showAvatar, showBadge } = props;

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          {showHi && (
            <span className="kt-header__topbar-welcome">
              Hi,
            </span>
          )}
          {showHi && (
            <span className="kt-header__topbar-username TT_C">
              {loggeduserdetail !== "" && loggeduserdetail !== undefined
                ? loggeduserdetail.userFullName
                : ""}
            </span>
          )}

          {/* {showAvatar && <img alt="Pic" src={user.pic} />} */}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
          }}
        >
          <div className="kt-user-card__avatar">
            {/* <img alt="Pic" className="kt-hidden" src={user.pic} /> */}
            {/* <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              S
            </span> */}
          </div>
          <div className="kt-user-card__name">
            <div>User - {loggeduserdetail?.userFullName}</div>
            <div>Role - {loggeduserdetail?.uRole}</div>
          </div>
        </div>
        <div className="kt-notification">
          {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Messages
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </a> */}
          <div className="kt-notification__custom">
            <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
              Sign Out
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserProfile;
