import React from "react";
import {
  Popup,
  Tooltip as MapToolTip,
  Marker

} from "react-leaflet";
import _ from "lodash";
import  RotatedMarker  from "./rotatedMarker";
import CustomMapTooltip from "./CustomMapTooltip";
import RotatedMarkerIcon from "./RotatedMarkerIcon";
import {
  SelectedpointerIcon,
} from './MapIcons'


function FocusAssetMarker(props) {
  const { position, selectedAsset, fullscreenmode, item, zoom } = props


  return (
    <>
      <RotatedMarker position={position}
        icon={SelectedpointerIcon}
        rotationAngle={_.toNumber(item.angle)}
       
                            onMouseOver={(e) => {
                              e.target.openPopup();
                            }}
                            onMouseOut={(e) => {
                              e.target.closePopup();
                            }}
                            onClick={() => selectedAsset(item)}
  
       
      >
        <RotatedMarker
          position={position}
          icon={RotatedMarkerIcon(item)}
          rotationAngle={_.toNumber(item.angle)}
          rotationOrigin="center"
           onClick={() => selectedAsset(item)}
        >

         


        </RotatedMarker> 
        <Popup>
          <CustomMapTooltip
            plateno={item.plateNo}
            issuedby={item.regEmirate}
            make={item.make}
            model={item.modelName}
            driver={item.driverName}
            phone={item.driverPhone}
            speed={item.speed}
            time={item.dateTime}
            temp1={item.tSensor1}
            temp2={item.tSensor2}
          />
        </Popup>
        {
            fullscreenmode === 1 &&
            <MapToolTip className="maptooltip"
              direction="bottom" offset={[0, 0]} opacity={1} permanent>
              {item.plateNo}
            </MapToolTip>
          }

      </RotatedMarker>
    </>
  )

}
export default FocusAssetMarker;