import { call,put, takeLatest } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { serverurl } from "../../app/router/config";
import axios from "axios";
export const actionTypes = {
  FunctionTreeActionRequested: "functionaltree/TEST_ACTION_REQUESTED",
  FunctionTreeActionSuccess: "functionaltree/TEST_ACTION_SUCCESS"
};

const initialState = {
  loading: false,
  treedata: ""
};

export const reducer = persistReducer(
  {
    storage,
    key: "functionaltree",
    whitelist: ["treedata"]
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.FunctionTreeActionRequested:
        return { ...state, loading: true };
      case actionTypes.FunctionTreeActionSuccess:
        return { ...state, loading: false, treedata: payload };
      default:
        return state;
    }
  }
);

export const actions = {
  getData: data => ({ type: actionTypes.FunctionTreeActionRequested, data }),
  dataSuccess: payload => ({
    payload,
    type: actionTypes.FunctionTreeActionSuccess
  })
};

export function* saga() {
  yield takeLatest(actionTypes.FunctionTreeActionRequested, function* fetchData(
    action
  ) {
    const {data} = yield call(axios.get,
      serverurl + "getFunctionalTree?userId=-1"
    )
    yield put(actions.dataSuccess(data));
  });
}
