import React from 'react'
import CustomMarker from './CustomMarker'
import CustomMapTooltip from "./CustomMapTooltip";
import {
    LayersControl,
    MapContainer,
    Marker,
    Polygon,
    Polyline,
    Popup,
    WMSTileLayer,
    Tooltip as MapToolTip,
  } from "react-leaflet";
  import _ from "lodash";

function POIFilter (props){
    const {data } = props
    return(
        <div>
  {/* POI Filter Secton */}
  {data !== "" && data !== undefined
     ? data.map((item, index) => {
       if (item.poiTypeId === 1) {
         // Marker Geofence
         var val = item.geomText;
         var point_latlon = val.split(" ");
         var lat = point_latlon[1];
         var lon = point_latlon[0];
         return (

          <CustomMarker position={[point_latlon[1], point_latlon[0]]} item={item} lat={lat} lon={lon}/>

        
         );
       } else if (item.poiTypeId === 2) {
         const geomTextvalue = _.toString(item.geomText).split(
           ","
         );
         var polyline_position = [];
         var polyline_positions = geomTextvalue.map((item) => {
           const split_pos = item.split(" ");
           return split_pos;
         });
         polyline_positions.map((item) => {
           _.toString(item.reverse());
           polyline_position.push(item);
         });

         var firstval = _.first(polyline_position);
         return (
           <Polyline
             positions={polyline_position}
             onMouseOver={(e) => {
                e.target.openPopup();
              }}
              onMouseOut={(e) => {
                e.target.closePopup();
              }}
             
 
           >
             <Popup>
               <CustomMapTooltip
                 poiname={item.poiName}
                 poicode={item.poiCode}
                 poigroup={item.poiGroupName}
               />
             </Popup>
           </Polyline>
         );
       } else if (item.poiTypeId === 3) {
         const geomTextvalue = _.toString(item.geomText).split(
           ","
         );
         var polygon_position = [];
         var polygon_positions = geomTextvalue.map((item) => {
           const split_pos = item.split(" ");
           return split_pos;
         });
         polygon_positions.map((item) => {
           _.toString(item.reverse());
           polygon_position.push(item);
         });
         var firstval = _.first(polygon_position);

         return (
           <Polygon
             positions={polygon_position}
             onMouseOver={(e) => {
                e.target.openPopup();
              }}
              onMouseOut={(e) => {
                e.target.closePopup();
              }}
 
           >
             <Popup>
               <CustomMapTooltip
                 poiname={item.poiName}
                 poicode={item.poiCode}
                 poigroup={item.poiGroupName}
               />
             </Popup>
           </Polygon>
         );
       }
     })
     : ""}
   {/* POI Filter Secton */}
        </div>
    )
}
export default POIFilter;