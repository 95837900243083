import React from 'react'

import {
    BankIcon,
    BlueIcon,
    BuildingIcon,
    ConstructionIcon,
    DarkGreenIcon,
    EmbassyIcon,
    FlagIcon,
    GoldIcon,
    HomeIcon,
    HospitalIcon,
    HotelIcon,
    LightGreenIcon,
    OfficeIcon,
    PurpleIcon,
    RedIcon,
    ResidentialIcon,
    RestaurantIcon,
    SchoolIcon,
    SeaportIcon,
    ShowroomIcon,
    StoreIcon,
  } from "./PoiIcons";
function MarkerIcon(item){
    return(
        item.displayIcon !== "" &&
                 item.displayIcon !== undefined
                 ? item.displayIcon === "bank.png"
                   ? BankIcon
                   : item.displayIcon === "Blue.png"
                     ? BlueIcon
                     : item.displayIcon === "building.png"
                       ? BuildingIcon
                       : item.displayIcon === "construction.png"
                         ? ConstructionIcon
                         : item.displayIcon === "DarkGreen.png"
                           ? DarkGreenIcon
                           : item.displayIcon === "embassy.png"
                             ? EmbassyIcon
                             : item.displayIcon === "gold.png"
                               ? GoldIcon
                               : item.displayIcon === "home.png"
                                 ? HomeIcon
                                 : item.displayIcon === "hospital.png"
                                   ? HospitalIcon
                                   : item.displayIcon === "hotel.png"
                                     ? HotelIcon
                                     : item.displayIcon === "LightGreen.png"
                                       ? LightGreenIcon
                                       : item.displayIcon === "office.png"
                                         ? OfficeIcon
                                         : item.displayIcon === "Purple.png"
                                           ? PurpleIcon
                                           : item.displayIcon === "red.png"
                                             ? RedIcon
                                             : item.displayIcon === "residential.png"
                                               ? ResidentialIcon
                                               : item.displayIcon === "restaurant.png"
                                                 ? RestaurantIcon
                                                 : item.displayIcon === "school.png"
                                                   ? SchoolIcon
                                                   : item.displayIcon === "seaport.png"
                                                     ? SeaportIcon
                                                     : item.displayIcon === "showroom.png"
                                                       ? ShowroomIcon
                                                       : item.displayIcon === "store.png"
                                                         ? StoreIcon
                                                         : FlagIcon
                 : FlagIcon
    )

}
export default MarkerIcon;