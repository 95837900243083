// Live View Section - Contains Vehicle Info, Focus Track, Share Location, KML Download, Today Timeline, Right-click Add as POI, Status Based Search, Area Based Search, Poi List
import { useRef } from "react";
import {
  Box,
  Button,
  createMuiTheme,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import ReactAudioPlayer from "react-audio-player";
import CancelIcon from "@material-ui/icons/Cancel";
import ListIcon from "@material-ui/icons/List";
import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import axios from "axios";
import { endOfToday, startOfToday } from "date-fns";
import dayjs from "dayjs";
import "leaflet-contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  LayersControl,
  Map,
  Marker,
  Polyline,
  Circle,
  Popup,
  WMSTileLayer,
  Tooltip as MapToolTip,
} from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import ITCImg from "../../../assets/infofleet/infofleet_logo_transparent.png";
import notificationsound from "../../../assets/infofleet/notification.mp3";
import * as liveViewAction from "../../../infofleet/ducks/liveView";
import { toAbsoluteUrl } from "../../../infofleet/utils/utils";
import {
  GOOGLEAPIKEY,
  MapLayer,
  mapurl,
  serverleturl,
  serverurl,
} from "../../router/config";
import { assetDropdown } from "./Dropdowns";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Loader from "./Loader";
// import "leaflet-rotatedmarker";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  OverspeedIcon,
  IdleIcon,
} from "../../component/MapComponents/MapIcons";

import SelectDriverDropdown from "../../component/controls/SelectDriverDropdown";
import TopBarFilter from "../../component/Liveview/Topbarfilter";
import SearchPOI from "../../component/controls/SearchPOI";
import SearchbyAreaname from "../../component/controls/SearchbyAreaname";
import CustomRotatedMarker from "../../component/MapComponents/CustomRotatedMarker";
import FocusAssetMarker from "../../component/MapComponents/FocusAssetMarker";
import POIFilter from "../../component/MapComponents/POIFilterMarker";
import CustomSideBarComponent from "../../component/Liveview/SidebarComponent";
import ListViewGridTable from "../../component/Liveview/ListViewGridTable";
import AddGeofencebody from "../../component/Liveview/AddGeofence";
import SingleAssetInfo from "../../component/Liveview/SingleAssetInfo";
import AddrightclickPOI from "../../component/Liveview/AddPOI";
import {getModalStyle} from "../../component/helpers/utils"

function Alertinfo(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: "20px",
    "& .disableDiv": {
      position: "relative",
      "&::after": {
        cursor: "not-allowed",
        position: "absolute",
        top: "0",
        right: "0",
        width: "100%",
        height: "100%",
        content: '""',
      },
    },
    "& .hidediv": {
      display: "none",
    },
    "& .showdiv": {
      display: "block",
    },
  },
  list: {
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
  },
  fullList: {
    width: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 175,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const itctheme = createMuiTheme({
  palette: {
    primary: {
      main: "#009684",
    },
    secondary: {
      main: "#1c4290",
    },
  },
});
// Styles


const LiveViewMap = ({ liveView, maplayer }) => {
  //console.log("hit 2");
  const mapRef = useRef();
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {

    localStorage.removeItem("allEntries");
    localStorage.removeItem("previoustrack");
    localStorage.removeItem("showtrack");
    localStorage.removeItem("trackvehicleid");
    localStorage.removeItem("selectedvehicleid");
    dispatch(liveViewAction.actions.selectedVId(""));
    getWindowDimension();

    axios
      .get(serverurl + "getLoggedUserAutoRefreshTime")
      .then(function (response) {
        const refreshdata = response.data;
        if (
          refreshdata !== "" &&
          refreshdata !== undefined &&
          refreshdata.time !== "" &&
          refreshdata.time !== undefined &&
          refreshdata.time > 0 &&
          refreshdata.active === true
        ) {
          const LiveViewStatusID = setInterval(
            () => {
              trackdatafunction();
              getrefreshliveviewdata();
              updatedselectedvehicle();
            },
            refreshdata.time !== "" &&
              refreshdata.time !== undefined &&
              refreshdata.time > 0
              ? (refreshdata.time / 60) * 60 * 1000
              : 10 * 60 * 1000
          );

          localStorage.setItem("LiveView_interval", LiveViewStatusID);
        }
      });

    axios
      .get(serverurl + "getTreeViewList", {
        params: {
          vehicleIds: -1,
        },
      })
      .then(function (response) {
        var fullassetarray =
          response.data !== "" && response.data !== undefined
            ? response.data.items.filter(function (obj) {
              return obj.type === "child";
            })
            : "";

        var fullvehicleid =
          fullassetarray !== "" &&
            fullassetarray !== undefined &&
            fullassetarray.length !== 0
            ? fullassetarray.map((item, index) => {
              return item.vehicleId;
            })
            : "";
        
        getallliveviewvehicle(fullvehicleid);
      });

   
  }, []);


  //start - to reset the map position when mapview changed to informap from google to avoid outof focus
  function handleOnSetView() {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    map.flyTo(defaultlatlng, 10);
  }

  useEffect(()=>{
    if(maplayer.MapLayer == "informap") {
      console.log("maplayer.MapLayer : ",maplayer.MapLayer)
      handleOnSetView()
    }
  },[maplayer.MapLayer])
  //end - to reset the map position when mapview changed to informap from google to avoid outof focus
  
  const [filterpoidetails, FilterPOIDetails] = React.useState("");
  const [listviewdrawer, setListViewDrawer] = React.useState(false);

  // Map Switch Code
  const mapslayer = maplayer.MapLayer;
  const cluster = maplayer.ClusterView;
  const mapzoomview = _.toString(maplayer.MapZoomView);
  const { BaseLayer } = LayersControl;
  // Map Switch Code

  const getWindowDimension = () => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  };

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };

  const [height, setHeight] = React.useState(window.innerHeight);
  const windowheight = height - 50;

  const [loaderview, LoaderView] = React.useState("");


  const mapview = {
    height: windowheight + "px",
    width: "100%",
    overflow: "hidden",
  };

  const classes = useStyles();
  var sidebarObj1 = "Sidebar"
  // const state = {
  //   // zoom: 9,
  //   bluemarble: false,
  //   // type: "distance",
  //   // demo: demo,
  // };


  const storedata = _.filter(liveView.data, function (val) {
    return val !== null;
  });

  const dispatch = useDispatch();
  const singleassetdata = liveView.selectedVId;
  const singleassetview = liveView.singleassetview;
  var alertdata = liveView.selectednotification;
 
  const [states, setState] = React.useState({
    // toggleicon: 2,
    viewlimit: "",
    expiryduration: "",
    mode: "",
    phoneno: "",
    name:
      singleassetdata.driverName +
      singleassetdata.id +
      singleassetdata.location,
    vehicleIds: "",
    zoom:
      alertdata !== "" && alertdata !== undefined && alertdata !== null
        ? 16
        : 10,
    sendMode: "1",
    lat: 25.190662,
    lng: 55.270411,
    selectedassetid: "",
    filter: false,
    locationfilter: false,
    poiIds: "",
    poifilter: false,
    focustrack: "",
    statetrackdata: "",
    previoustrackdata: "",
    alertscroll: false,
    deviceuniqhueid: "",
  });

  const defaultlatlng = [25.190662,55.270411]
  const [areatargetsearch, setAreatargetsearch] = React.useState("");
  const [areazoomlevel, setAreazoomlevel] = React.useState("");
  const [poistate, setPoistate] = React.useState({
    poigroup: "",
    poicategory: "",
    poilat: "",
    poilng: "",
  });

  const [geofencestatelatlng, setGeofencestatelatlng] = React.useState({

    geofencelat: "",
    geofencelng: "",
    radius: 400,

  })
  const [geofencecircleenable, setGeofenceCircleEnable] = React.useState(false);
  const [Geofencetoggleicon, SetGeofenceToggleicon] = React.useState(1);
  const [FocusAsset, setFocusAsset] = React.useState(false);
  const [sensorview, setSensorView] = React.useState(false);
  const [AddPOI, setAddPOI] = React.useState(false);
  const [actions, Actions] = React.useState();
  const [levels, Levels] = React.useState();
  const [vehiclelist, VehicleList] = React.useState();
  
  const [areanameposition, setAreanameposition] = React.useState({
    areapositionlat: "",
    areapositionlng: "",
  });
  const [fullscreenmode, setFullScreenMode] = React.useState(0);
  
  const [livepanelfilter, setLivepanelfilter] = React.useState({
    Moving: false,
    Parked: false,
    NoSignal: false,
    Stopped: false,
    Engineon: false,
    searchbyDriver: false,
    serachbyareaname: false,
  });

  const [InputEventStatus, setInputEventStatus] = React.useState("");

  var panelfiltered_storedata;

  if (livepanelfilter.searchbyDriver) {
    var driverfilterdata = [];
    for (var i = 0; i < states.vehicleIds.length; i++) {
      const driverfilter = storedata.filter(item => item.vehicleId === states.vehicleIds[i])
      if (driverfilter.length > 0) {
        driverfilterdata.push(driverfilter[0])
      }
    }
    panelfiltered_storedata = _.filter(driverfilterdata, function (val) {
      return val !== null;
    });

  }

  if (livepanelfilter.serachbyareaname) {
    panelfiltered_storedata = _.filter(liveView.filterData, function (val) {
      return val !== null;
    });

  }
  else if (livepanelfilter.Moving) {
    panelfiltered_storedata = _.filter(liveView.MovingFilterData, function (val) {
      return val !== null;
    });
  } else if (livepanelfilter.Parked) {
    panelfiltered_storedata = _.filter(liveView.ParkedFilterData, function (val) {
      return val !== null;
    });
  } else if (livepanelfilter.NoSignal) {
    panelfiltered_storedata = _.filter(liveView.NosignalFilterData, function (val) {
      return val !== null;
    });
  } else if (livepanelfilter.Stopped) {
    panelfiltered_storedata = _.filter(liveView.StoppedFilterData, function (val) {
      return val !== null;
    });
  } else if (livepanelfilter.Engineon) {
    panelfiltered_storedata = _.filter(liveView.EngineOnFilterData, function (val) {
      return val !== null;
    });
  }

  // Vehicle Count for Top Fixed Bar
  const filteredData =
    states.filter === true
      ? panelfiltered_storedata
      : storedata


  var vehicletypeBike =
    filteredData !== "" && filteredData !== undefined && filteredData !== null
      ? _.filter(filteredData, function (rec) {
        return rec.vehicleType === "Bike";
      })
      : "";
  var vehicletypeOthers =
    filteredData !== "" && filteredData !== undefined && filteredData !== null
      ? _.filter(filteredData, function (rec) {
        return rec.vehicleType !== "Bike";
      })
      : "";

  var nosignalCount =
    filteredData !== "" && filteredData !== undefined && filteredData !== null
      ? _.countBy(filteredData, function (rec) {
        return rec.vmode === "No Signal";
      })
      : "";
  const noScount = nosignalCount.true;
  var movingCount =
    filteredData !== "" && filteredData !== undefined
      ? _.countBy(filteredData, function (rec) {
        return rec.vmode === "Moving";
      })
      : "";
  const movecount = movingCount.true;
  var parkingCount =
    filteredData !== "" && filteredData !== undefined
      ? _.countBy(filteredData, function (rec) {
        return rec.vmode === "Parked";
      })
      : "";
  const parkcount = parkingCount.true;
  var stoppedCount =
    filteredData !== "" && filteredData !== undefined
      ? _.countBy(filteredData, function (rec) {
        return rec.vmode === "Stopped";
      })
      : "";
  const Stoppedcount = stoppedCount.true;

  var engineonCount =
    filteredData !== "" && filteredData !== undefined
      ? _.countBy(filteredData, function (rec) {
        return rec.vmode === "Idling Engine ON";
      })
      : "";
  const Engineoncount = engineonCount.true;

  const filterCount = {
    noScount: noScount,
    movecount: movecount,
    parkcount: parkcount,
    Stoppedcount: Stoppedcount,
    Engineoncount: Engineoncount
  }

  // Vehicle Count for Top Fixed Bar

  // start-Function to get all the Live View vehicle Ids and Passed to get the Live View Status Datas
  const getrefreshliveviewdata = async () => {
    axios
      .get(serverurl + "getTreeViewList", {
        params: {
          vehicleIds: -1,
        },
      })
      .then(function (response) {
        var fullassetarray =
          response.data !== "" && response.data !== undefined
            ? response.data.items.filter(function (obj) {
              return obj.type === "child";
            })
            : "";

        var fullvehicleid =
          fullassetarray !== "" &&
            fullassetarray !== undefined &&
            fullassetarray.length !== 0
            ? fullassetarray.map((item, index) => {
              return item.vehicleId;
            })
            : "";
        
        getallliveviewvehicle(fullvehicleid);
      });
  };
  // end-Function to get all the Live View vehicle Ids and Passed to get the Live View Status Datas

  // start-Function to get all the Live View Status
  const getallliveviewvehicle = async (event) => {
    LoaderView("true");
    const SERVICE_URL = serverurl + "getLiveStatusData";
    //console.log("hit 5");
    const requestBody = {
      vehicleIds:
        states.vehicleIds !== "" && states.vehicleIds !== undefined
          ? _.toString(states.vehicleIds)
          : _.toString(event),
    };
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response = await axios.post(SERVICE_URL, requestBody, config);

    LoaderView("false");
    if (response.data && (response.data).length > 0) {
      dispatch(liveViewAction.actions.getLiveView(response.data[0].items));
    }


    const storedata = (response.data && (response.data).length > 0) ? response.data[0].items : "";
    var moving_data = storedata ? storedata.filter(function (obj) {
      return obj.vmode === "Moving";
    })
      : "";

    dispatch(
      liveViewAction.actions.MovingFilterData(
        _.orderBy(moving_data, ["speed"], ["desc"])
      )
    );

    var parking_data = storedata ? storedata.filter(function (obj) {
      return obj.vmode === "Parked";
    }) : "";

    dispatch(
      liveViewAction.actions.ParkedFilterData(
        _.orderBy(parking_data, ["statusDurationMins"], ["desc"])
      )
    );

    var nosignal_data = storedata ? storedata.filter(function (obj) {
      return obj.vmode === "No Signal";
    }) : "";

    dispatch(
      liveViewAction.actions.NosignalFilterData(
        _.orderBy(nosignal_data, ["statusDurationMins"], ["desc"])
      )
    );

    var stopped_data = storedata ? storedata.filter(function (obj) {
      return obj.vmode === "Stopped";
    }) : "";

    dispatch(
      liveViewAction.actions.StoppedFilterData(
        _.orderBy(stopped_data, ["statusDurationMins"], ["desc"])
      )
    );

    var engineonidle_data = storedata ? storedata.filter(function (obj) {
      return obj.vmode === "Idling Engine ON";
    }) : "";

    dispatch(
      liveViewAction.actions.EngineOnFilterData(
        _.orderBy(engineonidle_data, ["statusDurationMins"], ["desc"])
      )
    );

  };
  // end-Function to get all the Live View Status

  // start-Function to Pass the Sidebar selected Vehicle details to Redux Store
  const sidebarselectedAsset = (e) => {
    axios
      .get(serverurl + "getCurrentStatusDetails", {
        params: {
          vehicleId: e.vehicleId,
        },
      })
      .then(function (response) {
        dispatch(liveViewAction.actions.selectedVId(response.data));
      });
    dispatch(liveViewAction.actions.singleassetview("hide"));
    setState({
      ...states,
      zoom: 16,
      lat: _.toNumber(e.latitude),
      lng: _.toNumber(e.longitude),
      selectedassetid: _.toNumber(e.vehicleId),
    });
    setFocusAsset(true);
    var DateFrom = startOfToday();
    var DateTo = endOfToday();
    var datefrom = moment(DateFrom).format("YYYY-MM-DD H:m:ss");
    var dateto = moment(DateTo).format("YYYY-MM-DD H:m:ss");
    localStorage.setItem("selectedvehicleid", e.vehicleId);
  };
  //end- Function to Pass the Sidebar selected Vehicle details to Redux Store

  //start- Function to Pass the Map selected Vehicle details to Redux Store
  function selectedAsset(e) {
    localStorage.removeItem("allEntries");
    localStorage.removeItem("previoustrack");
    localStorage.removeItem("showtrack");
    localStorage.removeItem("trackvehicleid");
    axios
      .get(serverurl + "getCurrentStatusDetails", {
        params: {
          vehicleId: e.vehicleId,
        },
      })
      .then(function (response) {
        dispatch(liveViewAction.actions.selectedVId(response.data));
      });

    axios
      .get(serverurl + "getInputEventStatus", {
        params: {
          vehicleId: e.vehicleId,
        },
      })
      .then(function (response) {
        setInputEventStatus(response.data);
      });

    dispatch(liveViewAction.actions.singleassetview("show"));
    localStorage.setItem("selectedvehicleid", e.vehicleId);

    setState({
      ...states,
      zoom: 16,
      lat: _.toNumber(e.latitude),
      lng: _.toNumber(e.longitude),
      selectedassetid: _.toNumber(e.vehicleId),
      focustrack: false,
      deviceuniqhueid: _.toString(e.uniqueId),
    });
    setFocusAsset(true);
  }
  //end- Function to Pass the Map selected Vehicle details to Redux Store

  //start- Function to get the selected Vehicle updated details on refresh Time
  function updatedselectedvehicle() {
    var selectedvehicle = localStorage.getItem("selectedvehicleid");

    if (
      selectedvehicle !== "" &&
      selectedvehicle !== undefined &&
      selectedvehicle !== null
    ) {
      axios
        .get(serverurl + "getCurrentStatusDetails", {
          params: {
            vehicleId: selectedvehicle,
          },
        })
        .then(function (response) {
          dispatch(liveViewAction.actions.selectedVId(response.data));
        });
    }
  }
  //end-Function to get the selected Vehicle updated details on refresh Time

  const ramarkChange = (name) => (event) => {
    setState({ ...states, [name]: event.target.value });
  };

  function sensorchange(event) {
    setSensorView(event.target.checked);
  }

  // Closing the selected Vehicle Info
  function closesingleasset() {
    setState({ ...states, focustrack: false });
    setFocusAsset(false);
    dispatch(liveViewAction.actions.singleassetview("hide"));
    localStorage.removeItem("allEntries");
    localStorage.removeItem("previoustrack");
    localStorage.removeItem("showtrack");
    localStorage.removeItem("trackvehicleid");
    localStorage.removeItem("selectedvehicleid");
  }
  // Closing the selected Vehicle Info

  // remove singleasset selection marker when clustergroup clicked to avoid extra vehicle marker of singleasset
  function closeSingleselection() {
    setFocusAsset(false);
  }



  // Right Sidebar Option
  const righttoggleClick = () => {
    sidebarObj1.toggle();
  };
  const onCreateGeofence = () => {
    sidebarObj1.element.style.visibility = "visible";
  };

  const opensidebar = () => {
    SetGeofenceToggleicon(2);
  };
  const closesidebar = () => {
    SetGeofenceToggleicon(1);
  };
  // Right Sidebar Option



  function poiidselect(event) {
    setState({ ...states, poiIds: event.value });

    LoaderView("true");
    setState({
      ...states,
      poifilter: true,
    });
    axios
      .get(serverurl + "GetPoisByPoiIds", {
        params: {
          poiIds: _.toString(event.value),
        },
      })
      .then(function (response) {
        LoaderView("false");
        FilterPOIDetails(response.data);
      });
  }
  // function poiidselectall(event) {
  //   console.log(event)
  //   console.log(event.value.length)
  //   // setState({ ...states, poiIds: event.value });
  // }

  const livepanelclick = (event) => {
    LoaderView("true");
    setFocusAsset(false); // set it as false whenever filter option changes 
    setState({
      ...states,
      filter:
        (event.target.name === "Moving" ||
          event.target.name === "Parked" ||
          event.target.name === "No Signal" ||
          event.target.name === "Stopped" ||
          event.target.name === "Engine ON") &&
          event.target.checked === true
          ? true
          : false,
      zoom: 9,
    });
    setLivepanelfilter({
      ...livepanelfilter,
      Moving: event.target.name === "Moving" ? event.target.checked : false,
      Parked: event.target.name === "Parked" ? event.target.checked : false,
      NoSignal:
        event.target.name === "No Signal" ? event.target.checked : false,
      Stopped: event.target.name === "Stopped" ? event.target.checked : false,
      Engineon:
        event.target.name === "Engine ON" ? event.target.checked : false,
      searchbyDriver: false,
      serachbyareaname: false
    });



    // axios
    //   .get(serverurl + "getSearchStatusGridData", {
    //     params: {
    //       target_param: "Engine Status",
    //       target_search: event.target.name,
    //     },
    //   })
    //   .then(function(response) {
    //     LoaderView("false");
    //     if (filter_name === "Moving") {
    //     } else {
    //       dispatch(
    //         liveViewAction.actions.filterData(
    //           _.orderBy(response.data, ["statusDurationMins"], ["desc"])
    //         )
    //       );
    //     }
    //   });
  };

  function locationChange(event) {
    setAreatargetsearch(event.target.value);
  }

  const location_submit = (event) => {
    LoaderView("true");
    setState({
      ...states,
      zoom: areazoomlevel,
      filter: true,
      locationfilter: true,
    });

    axios
      .get(serverurl + "getSearchStatusGridData", {
        params: {
          target_param: "Location",
          target_search: areatargetsearch,
        },
      })
      .then(function (response) {
        LoaderView("false");
        dispatch(liveViewAction.actions.filterData(response.data));
      });
    setLivepanelfilter({
      ...livepanelfilter,
      Moving: false,
      Parked: false,
      NoSignal: false,
      Stopped: false,
      Engineon: false,
      searchbyDriver: false,
      serachbyareaname: true,
    });

  };

  const poi_submit = (event) => {
    LoaderView("true");
    setState({
      ...states,
      poifilter: true,
    });
    axios
      .get(serverurl + "GetPoisByPoiIds", {
        params: {
          poiIds: _.toString(states.poiIds),
        },
      })
      .then(function (response) {
        LoaderView("false");
        FilterPOIDetails(response.data);
      });
  };

  const location_close = (event) => {
    setState({
      ...states,
      zoom: 9,
      filter: false,
      locationfilter: false,
    });
    setAreatargetsearch("");
    setAreazoomlevel("");
    setAreanameposition({
      areapositionlat: "",
      areapositionlng: "",
    });
  };
  const poi_close = (event) => {
    setState({
      ...states,
      poifilter: false,
    });
    FilterPOIDetails("");
  };
  // Top Bar Functions

  // start - Function for Focus Track Click
  function handleFocusTrackClick(focustrackChecked, existingTrackdata, PreviousTrackdata) {
    if (focustrackChecked === "true") {
      setState({
        ...states,
        focustrack: focustrackChecked,
        statetrackdata: existingTrackdata,
        previoustrackdata: PreviousTrackdata,
      });
    }
    else {
      setState({
        ...states,
        focustrack: false,
      });
    }

  }
  // end - Function for Focus Track Click

  function trackdatafunction() {

    var trackstatus = localStorage.getItem("showtrack");
    var trackvehicleid = localStorage.getItem("trackvehicleid");
    if (
      trackstatus === "true" &&
      trackvehicleid !== "" &&
      trackvehicleid !== undefined &&
      trackvehicleid !== null
    ) {
      axios
        .get(serverurl + "getCurrentStatusDetails", {
          params: {
            vehicleId: trackvehicleid,
          },
        })
        .then(function (response) {
          var existingEntries = JSON.parse(localStorage.getItem("allEntries"));
          var previoustrackEntries = JSON.parse(
            localStorage.getItem("previoustrack")
          );
          if (existingEntries == null) existingEntries = [];
          existingEntries.push(response.data);
          localStorage.setItem("allEntries", JSON.stringify(existingEntries));
          var existingTrackdata = JSON.parse(
            localStorage.getItem("allEntries")
          );
          setState({
            ...states,
            statetrackdata: existingTrackdata,
            previoustrackdata: previoustrackEntries,
            focustrack: trackstatus,
            //zoom: 16,
            lat: _.toNumber(response.data.latitude),
            lng: _.toNumber(response.data.longitude),
          });
        });
    }
  }
  // Function for Focus Track Click

  // Focus Icon Update Section
  var focusicon =
    FocusAsset === true &&
      singleassetdata !== "" &&
      singleassetdata !== undefined &&
      singleassetdata !== null &&
      storedata !== "" &&
      storedata !== undefined &&
      storedata !== null
      ? storedata.filter(function (obj) {
        return obj.vehicleId === singleassetdata.vehicleId;
      })
      : "";

  // Focus Icon Update Section

  // Function for right click Add POI
  const rightclickitems = [
    {
      text: "Add as POI",
      callback: (e) => addrightclickpoi(e),
    },
    {
      text: "Add as Geofence",
      callback: (e) => addrightclickgeofence(e),
    },
  ];
  function addrightclickpoi(e) {
    setPoistate({ ...poistate, poilat: e.latlng.lat, poilng: e.latlng.lng });
    setAddPOI(true);


  }
  // Modal Popup Close
  const handleClose = () => {
    setAddPOI(false);
  };


  // Start- geofence section
  function addrightclickgeofence(e) {

    const action_url = "GetGeofenceActions";
    axios.get(serverurl + action_url).then(function (response) {
      Actions(response.data);
    });

    const levels_url = "GetGeofenceLevels";
    axios.get(serverurl + levels_url).then(function (response) {
      Levels(response.data);
    });

    const url = "GetGeofenceSupportedVehicleTreeView";
    axios
      .get(serverurl + url, {
        params: {
          geofenceTypeId: 1,
          geofenceId: -1,
        },
      })
      .then(function (response) {
        VehicleList(response.data.items);
      });

    SetGeofenceToggleicon(1)
    setGeofencestatelatlng({ ...geofencestatelatlng, geofencelat: e.latlng.lat, geofencelng: e.latlng.lng });
    setGeofenceCircleEnable(true);

  }

  //start- geofence section
  // GeofenceModal Popup Close
  const handleReInitializeGeofence = () => {
    SetGeofenceToggleicon(2)
    sidebarObj1.toggle()
    setGeofenceCircleEnable(false)
    setGeofencestatelatlng({
      ...geofencestatelatlng,
      radius: 400,
      geofencelat: "",
      geofencelng: ""
    })
  };

  const geofenceRadius = (event) => {
    setGeofencestatelatlng({ ...geofencestatelatlng, radius: Math.round(event.target.value) });
  }

  const handleLoaderView = (value) => {
    LoaderView(value)
  }

  //end-geofence section



  function userselect(e) {
    var zoomlevel = e.itemData.add2 === "static_city" ? 13 : 14;
    setAreazoomlevel(zoomlevel);
    setAreatargetsearch(e.itemData.add5);
    setAreanameposition({
      areapositionlat: e.itemData.area_cy,
      areapositionlng: e.itemData.area_cx,
    });
  }

  // TV View Check
  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      var FS = 1;
    } else {
      var FS = 0;
    }
    setFullScreenMode(FS);
  });

  var speed_violation_count =
    storedata !== "" && storedata !== undefined && storedata !== null
      ? _.filter(storedata, function (data) {
        return data.speedViolationOn === 1;
      })
      : "";
  var idle_violation_count =
    storedata !== "" && storedata !== undefined && storedata !== null
      ? _.filter(storedata, function (data) {
        return data.idleViolationOn === 1;
      })
      : "";
  // TV View Check

  // List View Section
  const listViewDrawer = (open) => (event) => {
    setListViewDrawer(open);
  };
  // List View Section

  var currentdate = new Date();

  const position =
    alertdata !== "" && alertdata !== undefined && alertdata !== null
      ? [alertdata.latitude, alertdata.longitude]
      : states.locationfilter === true &&
        areanameposition !== "" &&
        areanameposition !== undefined
        ? [areanameposition.areapositionlat, areanameposition.areapositionlng]
        : focusicon && focusicon?.length > 0 ? [focusicon[0].latitude, focusicon[0].longitude]
          : [states.lat, states.lng];



  const handleAssetChange = (filter, vehicleIds) => {
    setState({
      ...states,
      filter: filter,
      vehicleIds: vehicleIds,
    });
    setLivepanelfilter({
      ...livepanelfilter,
      Moving: false,
      Parked: false,
      NoSignal: false,
      Stopped: false,
      Engineon: false,
      searchbyDriver: true,
      serachbyareaname: false,
    });

  }
  return (
    <>
      {/* {loaderview === "true" ? <Loader show="true" /> : ""} */}
      {loaderview === "true" ? "" : ""}
      <div className="LivePanelSidebar" id="Liveview">
        <div className="col-xl-12 p_0">
          {/* Top Fixed Header Section */}
          <>
            <div className="top_fixed_section">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-2 col-md-2 col-lg-2 pr_0">
                    <div className="row">
                      <SelectDriverDropdown handleAssetChange={handleAssetChange} />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-10 col-lg-10 icon_div_section p_0">
                    <div className="row">
                      <div className="col-sm-8 col-md-8 col-lg-6 liveviewicons">
                        <div className="row liveviewicons_row_div">
                          <TopBarFilter
                            livepanelfilter={livepanelfilter}
                            livepanelclick={livepanelclick}
                            filterCount={filterCount}
                            locationfilter={states.locationfilter}
                            fullscreen={fullscreenmode}
                            vehicletypeBike={vehicletypeBike}
                            vehicletypeOthers={vehicletypeOthers} />
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-3 flex_div flex_center auto_suggest_div">
                        <SearchbyAreaname locationChange={locationChange}
                          location_submit={location_submit}
                          location_close={location_close}
                          locationfilter={states.locationfilter}
                          filter={states.filter}
                          userselect={userselect}
                          areatargetsearch={areatargetsearch} />
                      </div>
                      <div className="col-md-3 col-lg-3  flex_div flex_center hide_sm">
                        <SearchPOI
                          poiidselect={poiidselect}
                          poi_submit={poi_submit}
                          poifilter={states.poifilter}
                          poi_close={poi_close}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* Top Fixed Header Section */}

          {/* Map Section */}
          <>
            <>
              <Map ref={mapRef}
                srs="EPSG:4326"
                // center={
                //   singleassetdata !== "" &&
                //   singleassetdata !== undefined &&
                //   singleassetdata.length !== 0
                //     ? [singleassetdata.latitude, singleassetdata.longitude]
                //     : position
                // }
                center={position}
                zoom={states.focustrack === "true" ? 16 :
                  mapzoomview === "default"
                    ? states.zoom
                    : mapzoomview === "building"
                      ? 16
                      : mapzoomview === "street"
                        ? 15
                        : mapzoomview === "highway"
                          ? 12
                          : 10
                }
                // maxZoom={17}
                style={mapview}
                noWrap="true"
                projection="EPSG:4326"
                bbox="[-180.0, -90.0, 180.0, 90.0]"
                // zoomControl={false}
                contextmenu={true}
                contextmenuWidth={130}
                contextmenuItems={rightclickitems}
              >
                {mapslayer === "google" ? (
                  <LayersControl position="topright">
                    <BaseLayer checked name="Google Maps Roads">
                      <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: GOOGLEAPIKEY }}
                        type={'roadmap'}
                      />
                    </BaseLayer>
                    <BaseLayer name="Google Maps Satellite">
                      <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: GOOGLEAPIKEY }}
                        type={'satellite'}
                      />
                    </BaseLayer>
                    <BaseLayer name="Google Maps terrain">
                      <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: GOOGLEAPIKEY }}
                        type={'terrain'}
                      />
                    </BaseLayer>
                    <BaseLayer name="Google Maps hybrid">
                      <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: GOOGLEAPIKEY }}
                        type={'hybrid'}
                      />
                    </BaseLayer>
                    <BaseLayer name="Google Maps Traffic">
                      <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: GOOGLEAPIKEY }}
                        googleMapsAddLayers={[{ name: "TrafficLayer" }]}
                      />
                    </BaseLayer>
                  </LayersControl>
                ) : (
                  <WMSTileLayer
                    tiled="true"
                    format="image/png"
                    srs="EPSG:4326"
                    layers={MapLayer}
                    minZoom="7"
                    maxZoom="16"
                    url={mapurl}
                    minResolution="auto"
                    maxResolution="auto"
                    projection="EPSG:4326"
                  // resolution ={[0.0054931640625, 0.00274658203125, 0.001373291015625, 6.866455078125E-4, 3.4332275390625E-4, 1.71661376953125E-4, 8.58306884765625E-5, 4.291534423828125E-5, 2.1457672119140625E-5, 1.0728836059570312E-5]}
                  />
                )}

                {cluster === false ? (
                  states.filter === false &&
                    storedata !== "" &&
                    storedata !== undefined &&
                    storedata !== null ? (
                    storedata.map((item, index) => {
                      let positionsed =
                        item.latitude !== "" &&
                          item.latitude !== undefined &&
                          item.longitude !== undefined &&
                          item.longitude !== ""
                          ? [item.latitude, item.longitude]
                          : position;
                      return (
                        <>
                          <CustomRotatedMarker position={positionsed} selectedAsset={selectedAsset} item={item} index={index} fullscreenmode={fullscreenmode} />
                        </>
                      );
                    })
                  ) : states.filter === true &&
                    panelfiltered_storedata !== "" &&
                    panelfiltered_storedata !== undefined &&
                    panelfiltered_storedata !== null ? (
                    panelfiltered_storedata.map((item, index) => {
                      let positionsed =
                        item.latitude !== "" &&
                          item.latitude !== undefined &&
                          item.longitude !== undefined &&
                          item.longitude !== ""
                          ? [item.latitude, item.longitude]
                          : position;
                      return (
                        <>
                          <CustomRotatedMarker position={positionsed} selectedAsset={selectedAsset} item={item} index={index} fullscreenmode={fullscreenmode} />
                        </>
                      );
                    })
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    <MarkerClusterGroup
                      onMouseOver={(e) => closeSingleselection()}
                    >
                      {states.filter === false &&
                        storedata !== "" &&
                        storedata !== undefined &&
                        storedata !== null
                        ? storedata.map((item, index) => {
                          let positionsed =
                            item.latitude !== "" &&
                              item.latitude !== undefined &&
                              item.longitude !== undefined &&
                              item.longitude !== ""
                              ? [item.latitude, item.longitude]
                              : position;
                          return (
                            <>
                              <CustomRotatedMarker position={positionsed} selectedAsset={selectedAsset} item={item} index={index} fullscreenmode={fullscreenmode} />
                            </>
                          );
                        })
                        : states.filter === true &&
                          panelfiltered_storedata !== "" &&
                          panelfiltered_storedata !== undefined &&
                          panelfiltered_storedata !== null
                          ? panelfiltered_storedata.map((item, index) => {
                            let positionsed =
                              item.latitude !== "" &&
                                item.latitude !== undefined &&
                                item.longitude !== undefined &&
                                item.longitude !== ""
                                ? [item.latitude, item.longitude]
                                : position;
                            return (
                              <>
                                <CustomRotatedMarker position={positionsed} selectedAsset={selectedAsset} item={item} index={index} fullscreenmode={fullscreenmode} />
                              </>
                            );
                          })
                          : ""}
                    </MarkerClusterGroup>
                  </>
                )}

                {/* <Circle center={position} radius={1000} /> */}
                {/* added extra vehicle marker for focus after every refresh when we select moving vehicle butthat vehicle goes into parked or stopped state. this time focus is missing,thats why added*/}
                {FocusAsset === true &&
                  singleassetdata !== "" &&
                  singleassetdata !== undefined &&
                  focusicon !== "" &&
                  focusicon !== undefined &&
                  focusicon !== null &&
                  focusicon.length > 0 ? (
                  <>
                    <FocusAssetMarker
                      position={[focusicon[0].latitude, focusicon[0].longitude]}
                      selectedAsset={selectedAsset}
                      item={focusicon[0]}
                      fullscreenmode={fullscreenmode}
                      zoom={states.zoom}
                    />
                  </>
                ) : (
                  ""
                )}

                {/* POI Filter Secton */}
                {filterpoidetails !== "" && filterpoidetails !== undefined
                  && <POIFilter data={filterpoidetails} />
                }
                {/* POI Filter Secton */}

                {/* Focus Track */}

                {/* Engine Start to Current  */}
                {states.focustrack === "true" &&
                  states.previoustrackdata !== "" &&
                  states.previoustrackdata !== undefined &&
                  states.previoustrackdata !== null &&
                  states.previoustrackdata.length > 1
                  ? states.previoustrackdata.map((item, index) => {
                    if (states.previoustrackdata[index + 1] !== undefined) {
                      let dataArr = [];
                      // var startpoint = trackresponse[0];
                      // var endpoint =
                      //   trackresponse[trackresponse.length - 1];
                      let speed =
                        item.speed <= 40
                          ? "#099345"
                          : item.speed >= 41 && item.speed <= 80
                            ? "#0000ff"
                            : item.speed >= 81 && item.speed <= 120
                              ? "#7d0000"
                              : item.speed >= 121
                                ? "#000000"
                                : "";
                      // if (item.vmode === "stopped") {
                      //   let position = [
                      //     parseFloat(item.latitude),
                      //     parseFloat(item.longitude),
                      //   ];
                      // }
                      dataArr.push([
                        parseFloat(item.latitude),
                        parseFloat(item.longitude),
                      ]);
                      dataArr.push([
                        parseFloat(
                          states.previoustrackdata[index + 1].latitude
                        ),
                        parseFloat(
                          states.previoustrackdata[index + 1].longitude
                        ),
                      ]);
                      return (
                        <>
                          {/* <Marker position={position} /> */}
                          <Polyline
                            key={index}
                            positions={dataArr}
                            // color={speed}
                            arrowheads={{ size: "15px", frequency: "500m" }}
                          />
                        </>
                      );
                    }
                  })
                  : ""}

                {/* Engine Start to Current  */}

                {/* Current Track */}

                {states.focustrack === "true" &&
                  states.statetrackdata !== "" &&
                  states.statetrackdata !== undefined &&
                  states.statetrackdata !== null &&
                  states.statetrackdata.length > 1
                  ? states.statetrackdata.map((item, index) => {
                    if (states.statetrackdata[index + 1] !== undefined) {
                      let dataArr = [];
                      // var startpoint = trackresponse[0];
                      // var endpoint =
                      //   trackresponse[trackresponse.length - 1];
                      let speed =
                        item.speed <= 40
                          ? "#099345"
                          : item.speed >= 41 && item.speed <= 80
                            ? "#0000ff"
                            : item.speed >= 81 && item.speed <= 120
                              ? "#7d0000"
                              : item.speed >= 121
                                ? "#000000"
                                : "";
                      // if (item.vmode === "stopped") {
                      //   let position = [
                      //     parseFloat(item.latitude),
                      //     parseFloat(item.longitude),
                      //   ];
                      // }
                      dataArr.push([
                        parseFloat(item.latitude),
                        parseFloat(item.longitude),
                      ]);
                      dataArr.push([
                        parseFloat(states.statetrackdata[index + 1].latitude),
                        parseFloat(
                          states.statetrackdata[index + 1].longitude
                        ),
                      ]);
                      return (
                        <>
                          {/* <Marker position={position} /> */}
                          <Polyline
                            key={index}
                            positions={dataArr}
                            // color={speed}
                            arrowheads={{ size: "15px", frequency: "500m" }}
                          />
                        </>
                      );
                    }
                  })
                  : ""}
                {/* Current Track */}

                {/* Engine Start Point */}

                {states.focustrack === "true" &&
                  states.previoustrackdata !== "" &&
                  states.previoustrackdata !== undefined &&
                  states.previoustrackdata !== null &&
                  states.previoustrackdata.length >= 2 ? (
                  <Marker
                    position={[
                      states.previoustrackdata[0].latitude,
                      states.previoustrackdata[0].longitude,
                    ]}
                    onMouseOver={(e) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>Engine Start Point</Popup>
                  </Marker>
                ) : (
                  ""
                )}

                {/* Engine Start Point */}

                {/* Focus Track */}

                {/* Alert View Icon */}

                {alertdata !== "" &&
                  alertdata !== undefined &&
                  alertdata !== null ? (
                  <Marker
                    position={[alertdata.latitude, alertdata.longitude]}
                    // position={["25.313644", "55.374236"]}
                    icon={
                      alertdata.alertName === "Over Speed"
                        ? OverspeedIcon
                        : IdleIcon
                    }
                    onMouseOver={(e) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e) => {
                      e.target.closePopup();
                    }}
                  >
                    {/* <Popup>
                      <CustomMapTooltip
                        poiname={item.poiName}
                        poicode={item.poiCode}
                        poigroup={item.poiGroupName}
                      />
                    </Popup> */}
                  </Marker>
                ) : (
                  ""
                )}

                {/* Alert View Icon */}

                <FullscreenControl
                  position="topright"
                  title="Full Screen"
                  id="fullscreen-div"
                />
                {/* <ZoomControl position="topright" /> */}

                {/* Violation Audio Notification  */}

                {fullscreenmode === 1 &&
                  ((speed_violation_count !== "" &&
                    speed_violation_count !== undefined &&
                    speed_violation_count !== null &&
                    speed_violation_count.length > 0) ||
                    (idle_violation_count !== "" &&
                      idle_violation_count !== undefined &&
                      idle_violation_count !== null &&
                      idle_violation_count.length > 0)) ? (
                  <ReactAudioPlayer
                    src={notificationsound}
                    autoPlay={
                      (speed_violation_count !== "" &&
                        speed_violation_count !== undefined &&
                        speed_violation_count !== null &&
                        speed_violation_count.length > 0) ||
                        (idle_violation_count !== "" &&
                          idle_violation_count !== undefined &&
                          idle_violation_count !== null &&
                          idle_violation_count.length > 0)
                        ? true
                        : false
                    }
                    // autoPlay
                    controls={false}
                    loop={
                      (speed_violation_count !== "" &&
                        speed_violation_count !== undefined &&
                        speed_violation_count !== null &&
                        speed_violation_count.length > 0) ||
                        (idle_violation_count !== "" &&
                          idle_violation_count !== undefined &&
                          idle_violation_count !== null &&
                          idle_violation_count.length > 0)
                        ? true
                        : false
                    }
                  />
                ) : (
                  ""
                )}

                {/* Violation Audio Notification  */}

                {/* Full Screen Status */}

                {fullscreenmode === 1 ? (
                  <>
                    <div className="liveviewicons_row_div fullscreen_status_icons">
                      <div>
                        <img
                          alt="Informap Logo"
                          src={ITCImg}
                          className="logoimg mr_10"
                        />
                      </div>
                      <TopBarFilter
                        livepanelfilter={livepanelfilter}
                        livepanelclick={livepanelclick}
                        filterCount={filterCount}
                        locationfilter={states.locationfilter}
                        fullscreen={fullscreenmode}
                        vehicletypeBike={vehicletypeBike}
                        vehicletypeOthers={vehicletypeOthers} />
                      <div className="fullscreen_time">
                        {moment(currentdate).format("llll")}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* Full Screen Status */}

                {/* Start - Add Geofence Circle marker while rightclick "Add as geofence" option*/}

                {geofencecircleenable === true && geofencestatelatlng.geofencelat !== "" && geofencestatelatlng.geofencelng !== "" ? (
                  <>
                    <Circle
                      center={
                        [geofencestatelatlng.geofencelat, geofencestatelatlng.geofencelng]

                      }
                      radius={
                        geofencestatelatlng.radius !== "" && geofencestatelatlng.radius !== undefined
                          ? geofencestatelatlng.radius
                          : 400
                      }
                    />
                    <Marker
                      position={
                        [geofencestatelatlng.geofencelat, geofencestatelatlng.geofencelng]
                      }
                    ></Marker>
                  </>
                ) : (
                  ""
                )}

                {/* End - Add Geofence Circle marker while rightclick "Add as geofence" option*/}

              </Map>
            </>
          </>
          {/* Map Section */}
        </div>

        {/* Sidebar Component */}
        <div className="control-section">
          <div id="wrapper">

            <CustomSideBarComponent
              filteredData={states.filter === false ? filteredData : panelfiltered_storedata}
              sidebarselectedAsset={sidebarselectedAsset}
              singleassetdata={singleassetdata}
              sensorview={sensorview}
            />

          </div>
        </div>
        {/* Sidebar Component */}


        {/* Right Click Add Geofence Modal */}
        <div className="control-section">
          <div id="wrapper">
            <SidebarComponent
              id="default-sidebar1"
              ref={(Sidebar) => (sidebarObj1 = Sidebar)}
              style={{ visibility: "visible" }}
              close={closesidebar}
              open={opensidebar}
              created={onCreateGeofence}
              type="Push"
              position="Left"
            >
              <AddGeofencebody geofencestatelatlng={geofencestatelatlng}
                vehiclelist={vehiclelist}
                actions={actions}
                levels={levels}
                handleReInitializeGeofence={handleReInitializeGeofence}
                geofenceRadius={geofenceRadius}
                handleLoaderView={handleLoaderView} />
            </SidebarComponent>
            <div>
              <div className="center-align">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="quick-panel-tooltip">
                      Add Geofence
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={righttoggleClick}
                    className={`${Geofencetoggleicon === 1 ? "right_300" : "right_0"
                      } ms_toggle_icon1`}
                  >
                    {Geofencetoggleicon === 1 ? (
                      //<ChevronRightIcon />
                      <img alt="Toggle icon" src={toAbsoluteUrl("/media/icons/Add-geofence.png")} />
                    ) : (
                      <ChevronLeftIcon />

                    )}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>

          </div>
        </div>


        {/* List View Section */}
        <span className="ListViewIcon">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Grid View</Tooltip>}
          >
            <ListIcon
              className="ListViewIcon_svg"
              onClick={listViewDrawer(true)}
            />
          </OverlayTrigger>
        </span>
        {listviewdrawer === true ? (
          <>
            <div className="List_View">
              <div className="List_view_head">
                <>
                  <span>
                    <h4>Grid View</h4>
                  </span>
                  <span className="listview_cancel_btn">
                    <CancelIcon
                      className="listview_cancel_btn_svg"
                      onClick={listViewDrawer(false)}
                    />
                  </span>
                </>
              </div>

              {states.filter === false &&
                filteredData !== "" &&
                filteredData !== undefined &&
                filteredData.length !== 0 ?

                <ListViewGridTable records={filteredData} sidebarselectedAsset={sidebarselectedAsset} singleassetdata={singleassetdata} />

                : states.filter === true &&
                  panelfiltered_storedata !== "" &&
                  panelfiltered_storedata !== undefined &&
                  panelfiltered_storedata !== null ?

                  <ListViewGridTable records={panelfiltered_storedata} sidebarselectedAsset={sidebarselectedAsset} singleassetdata={singleassetdata} />

                  : ""}

            </div>
          </>
        ) : (
          ""
        )}

        {/* List View Section */}
      </div>
      {/* start - Single Asset Info Section  */}
      {singleassetdata !== "" ? (
        <>
          {console.log("signle selected dat and view : ", singleassetdata, singleassetview)}
          <div
            className={`${singleassetview === "hide" ? "hide" : "show"
              } single_asset_details pull-right`}
          >
            {console.log("signle selected dat and view inside div: ", singleassetdata, singleassetview)}
            <SingleAssetInfo
              singleassetdata={singleassetdata}
              closesingleasset={closesingleasset}
              deviceuniqhueid={states.deviceuniqhueid}
              handleFocusTrackClick={handleFocusTrackClick}
              InputEventStatus={InputEventStatus} />

          </div>  </>) : ""

      }
      {/* end - Single Asset Info Section  */}

      {/* Right Click Add POI Modal */}
      <Modal
        open={AddPOI}
        onClose={handleClose}
        aria-labelledby="nearby_vehicle-title"
        aria-describedby="nearby_vehicle"
      >
        <AddrightclickPOI
          poilat={poistate.poilat}
          poilng={poistate.poilng}
          handleClose={handleClose}
        />
      </Modal>
      {/* Right Click Add POI Modal */}

    </>
  );
};
const mapStateToProps = ({ liveView, maplayer }) => ({
  liveView,
  maplayer,
});
export default connect(mapStateToProps, { liveViewAction })(LiveViewMap);
