/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import LiveviewPage from "../pages/home/Liveview";
import { LayoutContextProvider } from "../../infofleet";
import Layout from "../../infofleet/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";

import { fetechSessionData } from "../pages/home/report/Dropdown";

import { serverurl } from "./config";
import axios from "axios";

export const Routes = withRouter(({ history }) => {
  useEffect(() => {
    getsession();
    setInterval(() => {
      getsession();
    }, 16 * 60 * 1000);
  }, []);

  const getsession = () => {
    const url = "isSessionExist";
    axios.get(serverurl + url).then(function(response) {
      if (response.data === false) {
        history.push("/logout");
      }
    });
  };

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     <Redirect to={userLastLocation} />;
  //   } else {
  //     // alert("This page is not reloaded");
  //   }
  // }

  const { 
    isAuthorized,
    menuConfig,
    quickoptionConfig,
    userLastLocation,
    functionaltree,
  } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      quickoptionConfig,
      functionaltree,
      isAuthorized: auth.authToken === 1,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider
      history={history}
      menuConfig={menuConfig}
      quickoptionConfig={quickoptionConfig}
      functionaltree={functionaltree}
    >
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to={userLastLocation} />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage userLastLocation={userLastLocation} auth={isAuthorized}/>
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
