// Live View Section Main Component
import React from "react";
import LiveviewMap from "../home/Map";

export default function Liveview() {
  return (
    <>
      <LiveviewMap />
    </>
  );
}
