import React, { useEffect } from 'react'
import { Grid, FormControl, Button } from "@material-ui/core"
import CustomMultiSelectComponent from '../FormControls/MultiSelectComponent';
import { serverurl } from "../../router/config";
import axios from 'axios';

function SearchPOI(props) {
    const { poiidselect, poi_submit, poifilter, poi_close } = props
    const [PSpoisbygroupname, panelsearchPoisByGroupname] = React.useState("");

    useEffect(() => {
        const getPOIdata = async () => {
            await axios.get(serverurl + "GetLoggedUserPoisByGroup").then(function (response) {
                panelsearchPoisByGroupname(response.data.items);
            });
        }
        getPOIdata()
    }, [])

    const POIlist =
        PSpoisbygroupname !== ""
            ? PSpoisbygroupname?.filter(function (obj) {
                return obj.type !== "parent";
            })
            : "";
    const poifields = {
        groupBy: "poiGroupName",
        text: "poiName",
        value: "id",
    };

    return (
        <>
            <div className="col-sm-10 col-md-10 col-lg-10 p_0 ">
            <FormControl className="col-12">
                <CustomMultiSelectComponent
                    fields={poifields}
                    dataSource={POIlist}
                    placeholder="Show POI"
                    change={poiidselect}
                    filterBarPlaceholder="Search POI"
                    name="Poi"
                />

            </FormControl>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2 p_0">
                {poifilter === false ? (
                    <Button
                        onClick={poi_submit}
                        className="liveview_search"
                    >
                        <i className="la la-search la-2x" />
                    </Button>
                ) : poifilter === true ? (
                    <Button
                        onClick={poi_close}
                        className="liveview_search liveview_close"
                    >
                        <i className="la la-close la-2x" />
                    </Button>
                ) : ""}
            </div>
        </>
    )
}
export default SearchPOI;