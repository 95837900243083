import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import * as functionaltree from "../ducks/functionaltree";
import * as quickoptions from "../ducks/quickoptions";
// import * as sidebardashboard from "../ducks/sidebardashboard";
// import * as asset from "../ducks/asset";
// import * as driver from "../ducks/driver";
import * as maplayer from "../ducks/maplayer";
/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
  children,
  menuConfig,
  layoutConfig,
  htmlClassService
}) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);

  useEffect(() => {
    dispatch(builder.actions.setMenuConfig(menuConfig));
    dispatch(
      functionaltree.actions.getData("updated data from layout initializer")
    );
    dispatch(
      quickoptions.actions.getData("updated data from layout initializer")
    );
    // dispatch(
    //   sidebardashboard.actions.getDashboardTrack("updated data from layout initializer")
    // );
    // dispatch(
    //   sidebardashboard.actions.getDashboardAlert("updated data from layout initializer")
    // );
    // dispatch(
    //   sidebardashboard.actions.getDashboardFleet("updated data from layout initializer")
    // );
    // dispatch(asset.actions.getData("updated data from layout initializer"));
    // dispatch(driver.actions.getData("updated data from layout initializer"));
  }, [dispatch, menuConfig]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  // useEffect(() => {
  //   dispatch(functionaltree.actions.getData('updated data from layout initializer'));
  // },[])

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
