
import {

    MovingBikeIcon,
    ParkedBikeIcon,
    NosignalBikeIcon,
    EngineONBikeIcon,
    StoppedBikeIcon,
    MovingpointerIcon,
    ParkedpointerIcon,
    StoppedpointerIcon,
    NosignalpointerIcon,
    AnimatedIcon,
    AnimatedIdleIcon,
    OverspeedIcon,
    IdleIcon,
    SelectedpointerIcon,
  } from './MapIcons'
function RotatedMarkerIcon(item){

return( 
    item.idleViolationOn === 1
      ? AnimatedIdleIcon
      : item.speedViolationOn === 1
        ? AnimatedIcon
        : item.vmode === "Parked"
          ? item.vehicleType === "Bike"
            ? ParkedBikeIcon
            : ParkedpointerIcon
          : item.vmode === "Moving"
            ? item.vehicleType === "Bike"
              ? MovingBikeIcon
              : MovingpointerIcon
            : item.vmode === "No Signal"
              ? item.vehicleType === "Bike"
                ? NosignalBikeIcon
                : NosignalpointerIcon
              : item.vmode === "Stopped"
                ? item.vehicleType === "Bike"
                  ? StoppedBikeIcon
                  : StoppedpointerIcon
                : ParkedpointerIcon
)
}

export default RotatedMarkerIcon;