import { call,put, takeLatest } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { serverurl } from "../../app/router/config";
import axios from "axios";
export const actionTypes = {
  QuickoptionsActionRequested: "quickoptions/TEST_ACTION_REQUESTED",
  QuickoptionsActionSuccess: "quickoptions/TEST_ACTION_SUCCESS"
};

const initialState = {
  loading: false,
  quickoptiondata: ""
};

export const reducer = persistReducer(
  {
    storage,
    key: "quickoptions",
    whitelist:["quickoptiondata"]
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.QuickoptionsActionRequested:
        return { ...state, loading: true };
      case actionTypes.QuickoptionsActionSuccess:
        return { ...state, loading: false, quickoptiondata: payload };
      default:
        return state;
    }
  }
);

export const actions = {
  getData: data => ({ type: actionTypes.QuickoptionsActionRequested, data }),
  dataSuccess: payload => ({
    payload,
    type: actionTypes.QuickoptionsActionSuccess
  })
};

export function* saga() {
  yield takeLatest(actionTypes.QuickoptionsActionRequested, function* fetchData(
    action
  ) {
    const {data} = yield call(axios.get,
      serverurl + "getReportQuickHistoryTimeSpan"
    )
    yield put(actions.dataSuccess({data :  data}));
  });
}
