import React from 'react'
import {   AutoCompleteComponent  } from "@syncfusion/ej2-react-dropdowns";
export default function AutoComplete(props) {

    const { id, dataSource ,fields ,placeholder , select, minLength, value, cssClass,...others } = props;

    return (
        <AutoCompleteComponent
            id={id}
            dataSource={dataSource}
            fields={fields}
            placeholder={placeholder}
            highlight={true}
            select={select}
            minLength={minLength}
            value={value}
            cssClass={cssClass}
            disabled={dataSource ? false : true}
            {...others}
        />
    )
}