import React from 'react'
import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import clsx from "clsx";
import _ from "lodash";
import ToggleImg from "../../../assets/infofleet/live.png";
import { makeStyles, Button } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Styles
const useStyles = makeStyles((theme) => ({

  list: {
    width: "100%",
  },

}));

function CustomSideBarComponent(props) {
  const { filteredData,
    sidebarselectedAsset,
    singleassetdata,
    sensorview,
  } = props
  const [height, setHeight] = React.useState(window.innerHeight);
  const windowheight = height - 50;
  const sidebarview = {
    height: windowheight - 65 + "px",
    width: "100%",
    overflowX: "hidden",
    visibility: "visible",
  };
  const [toggleicon, ToggleIcon] = React.useState(2);
  const classes = useStyles()
  var sidebarObj = "Sidebar";
  // Sidebar Option
  const toggleClick = (e) => {
    sidebarObj.toggle();
  };
  const onCreate = () => {
    sidebarObj.element.style.visibility = "visible";
  };
  // Sidebar Option


  const open = () => {
    ToggleIcon(2);
  };
  const close = () => {
    ToggleIcon(1);
  };
  // Sidebar Option
  return (
    <div className="control-section">
      <div id="wrapper">
        <SidebarComponent
          id="default-sidebar"
          ref={(Sidebar) => (sidebarObj = Sidebar)}
          // style={{ visibility: "visible" }}
          close={close}
          open={open}
          created={onCreate}
          type="Push"
          position="Left"
          style={sidebarview}
        >
          <div className={clsx(classes.list)} role="presentation">
            <>
              <div>
                {
                  filteredData !== "" &&
                    filteredData !== undefined &&
                    filteredData.length !== 0 ? (
                    <>
                      {filteredData.map((lpdata, index) => (
                        <div
                          key={lpdata.id}
                          className="col-ls-12 col-md-12 mt_5"
                          onClick={() => sidebarselectedAsset(lpdata)}
                        >
                          {" "}
                          <div
                            className={`${_.toNumber(singleassetdata.vehicleId) ===
                                lpdata.vehicleId
                                ? lpdata.vmode === "Moving"
                                  ? "selectedvehiclepanel_moving"
                                  : lpdata.vmode === "Parked"
                                    ? "selectedvehiclepanel_parked"
                                    : lpdata.vmode === "Stopped"
                                      ? "selectedvehiclepanel_stopped"
                                      : lpdata.vmode === "Idling Engine ON"
                                        ? "selectedvehiclepanel_engineon"
                                        : lpdata.vmode === "No Signal"
                                          ? "selectedvehiclepanel_nosignal"
                                          : ""
                                : ""
                              } liveview_section`}
                          >
                            <div className="col-ls-12 col-md-12">
                              <div className="row">
                                <span className="col-md-7 p_0">
                                  {lpdata.plateNo} ( {lpdata.regEmirate} )
                                </span>
                                <span
                                  className={`${lpdata.vmode === "Moving"
                                      ? "liveview_label_moving"
                                      : lpdata.vmode === "Parked"
                                        ? "liveview_label_parked"
                                        : lpdata.vmode === "Stopped"
                                          ? "liveview_label_stopped"
                                          : lpdata.vmode === "Idling Engine ON"
                                            ? "liveview_label_engineon"
                                            : lpdata.vmode === "No Signal"
                                              ? "liveview_label_nosignal"
                                              : ""
                                    } col-md-5 text-center p_0 br_2 mh_20`}
                                >
                                  {lpdata.vmode === "Idling Engine ON"
                                    ? "Idling Eng ON"
                                    : lpdata.vmode}
                                </span>
                              </div>
                            </div>
                            <div className="col-ls-12 col-md-12">
                              <div className="row">
                                <span>
                                  {lpdata.assetName} - {lpdata.assetMake} /{" "}
                                  {lpdata.assetModel}
                                </span>
                              </div>
                            </div>
                            <div className="col-ls-12 col-md-12">
                              <div className="row lv_dd">
                                <span>{lpdata.driverName}</span>
                                <span>{lpdata.speed} Km/h</span>
                                <span>{lpdata.dateTime}</span>
                              </div>
                            </div>
                            {sensorview === true ? (
                              <>
                                <div className="col-ls-12 col-md-12">
                                  <div className="row justify_sb">
                                    <span>
                                      Temp 1-
                                      {lpdata.tSensor1 !== "" &&
                                        lpdata.tSensor1 !== undefined
                                        ? lpdata.tSensor1
                                        : "Nil"}
                                    </span>
                                    <span>
                                      Temp 2-
                                      {lpdata.tSensor2 !== "" &&
                                        lpdata.tSensor2 !== undefined
                                        ? lpdata.tSensor2
                                        : "Nil"}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
              </div>
            </>
          </div>

        </SidebarComponent>
        <div>
          <div className="center-align">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Live View</Tooltip>}
            >
              <Button
                onClick={toggleClick}
                className={`${toggleicon === 1 ? "ms_toggle_icon right_300" : "ms_toggle_icon right_0"
                  } ms_toggle_icon`}
              >
                <img alt="Toggle icon" src={ToggleImg} />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomSideBarComponent;