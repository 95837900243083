import React from "react";
import store from '../../app/store/store'
// function to check if loggedin user has the access to particular module(menu access)
function AuthCheck(level0_Id, level1_Id,level2_Id,level3_Id,level4_Id) {
  const states = store.getState();

  if (states.functionaltree?.treedata && states.functionaltree?.treedata?.length > 0) {
      const storedata = states.functionaltree.treedata;
     
      const fleetlevel =
          storedata !== "" && storedata !== undefined && storedata !== null
              ? storedata?.filter((item) => item.itemId === level0_Id)
              : "";
      if (fleetlevel.length === 0) {
          return false
      }
      else {
        if(level1_Id === undefined){
            return true
        }
        else  if (level1_Id) {
              const sublevel =
                  fleetlevel !== "" && fleetlevel !== undefined && fleetlevel !== null
                      ? fleetlevel[0].nextLevel.filter((item) => item.itemId === level1_Id)
                      : "";
              if (sublevel.length === 0) {
                  return false
              }
              else {
                if(level2_Id === undefined){
                    return true
                }
                else if (level2_Id) {
                    const sublevel1 =
                    sublevel !== "" && sublevel !== undefined && sublevel !== null
                            ? sublevel[0].nextLevel.filter((item) => item.itemId === level2_Id)
                            : "";
                    if (sublevel1.length === 0) {
                        return false
                    }
                    else {
                        if(level3_Id === undefined){
                            return true
                        }
                        else if (level3_Id) {
                            const sublevel2 =
                            sublevel1 !== "" && sublevel1 !== undefined && sublevel1 !== null
                                    ? sublevel1[0].nextLevel.filter((item) => item.itemId === level3_Id)
                                    : "";
                            if (sublevel2.length === 0) {
                                return false
                            }
                            else {
                                if(level4_Id === undefined){
                                    return true
                                }
                                else if (level4_Id) {
                                    const sublevel3 =
                                    sublevel2 !== "" && sublevel2 !== undefined && sublevel2 !== null
                                            ? sublevel2[0].nextLevel.filter((item) => item.itemId === level4_Id)
                                            : "";
                                    if (sublevel3.length === 0) {
                                        return false
                                    }
                                    else {
                                        return true
                                    }
                                }
                                else {
                                    return true
                                }
                            }
                        }
                        else {
                            return true
                        }
                    }
                }
                else {
                    return true
                }
              }
          }
          else {
              return true
          }

      }


  }
  else {
      return false
  }

}


export default AuthCheck;