// Support Page: this contains  Account Support, Support Ticket, Speed Radar Limit and Petrol Prices

import React, { useState, useEffect, lazy } from "react";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {Link, Route, Switch} from 'react-router-dom'
const DataDownload = lazy(() => import("./support/DataDownload"));
const TrainingVideos = lazy(() => import("./TraningVideos/TrainingVideos"))


export default function Support() {
  useEffect(() => {
    getWindowDimension();
  }, []);

  const getWindowDimension = () => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  };

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };

  const [height, setHeight] = React.useState(window.innerHeight);
  const windowheight = height - 50;
  const ms = {
    height: windowheight + "px",
    width: "100%",
  };

  return (
    <>
    <Switch>
      
    <Route path="/Support/TrainingVideos" component={TrainingVideos} />
    <Route path="/Support/DataDownload" component={DataDownload} />
    
  
      <div className="manage_settings fleet_section" style={ms}>
        <div class="main_section">
          <h4 class="mb_20">Support</h4>
          <div className="row support_section">
            <div className="col-md-6 col-lg-3 mt_10">
              <a
                href="mailto:support@itcshj.ae"
                target="_blank"
                alt="Infofleet"
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      Account Support
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
              <a
                href="http://infofleetsupport.com/"
                target="_blank"
                alt="Infofleet"
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      Support Ticket
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
              <a
                href="https://www.dubaipolice.gov.ae/wps/portal/home/opendata/Street_Speed_Limits"
                target="_blank"
                alt="Infofleet"
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      Dubai Speed Radar Limit
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
              <a
                href="https://www.bayut.com/mybayut/petrol-prices-uae-update/"
                target="_blank"
                alt="Infofleet"
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      UAE Petrol Prices
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
             <Link to="/Support/TrainingVideos">
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      Training Videos
                    </Typography>
                  </CardContent>
                </Card>
                </Link>
              
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
             <Link to="/Support/DataDownload">
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      Data Download
                    </Typography>
                  </CardContent>
                </Card>
                </Link>
              
            </div>
            <div className="col-md-6 col-lg-3 mt_10">
              <a
                href="https://infofleetcom.tawk.help/"
                target="_blank"
                alt="Infofleet"
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      FAQ
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </div>
          </div>
        </div>
      </div>
      </Switch>
      
    </>
  );
}
