/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect } from "react";
import BellIcon from "react-bell-icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NotificationBadge, { Effect } from "react-notification-badge";
import { connect, useDispatch } from "react-redux";
import { serverurl } from "../../../app/router/config";
// import useSound from "use-sound";
// import sampleaudio from "../../../assets/infofleet/notification_audio.mp3";
import * as liveViewAction from "../../../infofleet/ducks/liveView";
import KTOffcanvas from "../../_assets/js/offcanvas";

function NotificationPanelToggler({ liveView }) {
  const [notificationcount, SetNotificationCount] = React.useState("");
  const [notificationread, SetNotificationRead] = React.useState(0);

  useEffect(() => {
    axios
      .get(serverurl + "getRealTimeNotificationCount")
      .then(function(response) {
        SetNotificationCount(response.data);
      });

    axios
      .get(serverurl + "getLoggedUserAutoRefreshTime")
      .then(function(response) {
        const refreshdata = response.data;
        if (
          refreshdata !== "" &&
          refreshdata !== undefined &&
          refreshdata.time !== "" &&
          refreshdata.time !== undefined &&
          refreshdata.time > 0 &&
          refreshdata.active === true
        ) {
          const nIntervId  = setInterval(
            () => {
              axios
                .get(serverurl + "getRealTimeNotificationCount")
                .then(function(response) {
                  SetNotificationCount(response.data);
                });
            },
            refreshdata.time !== "" &&
              refreshdata.time !== undefined &&
              refreshdata.time > 0
              ? (refreshdata.time / 60) * 60 * 1000
              : 10 * 60 * 1000
          );
          localStorage.setItem(
            "notification_interval",
            nIntervId
          );
        }
      });
  }, []);
  const dispatch = useDispatch();
  // const soundUrl = sampleaudio;
  // const [play, { stop }] = useSound(soundUrl, { volume: 0.5 });
  // eslint-disable-next-line
  const panel = document.getElementById("kt_quick_panel");

  // eslint-disable-next-line
  const offCanvas = new KTOffcanvas(panel, {
    overlay: true,
    baseClass: "kt-quick-panel",
    closeBy: "kt_quick_panel_close_btn",
    toggleBy: "kt_quick_panel_toggler_btn",
  });

  function testclick(event) {
    axios.get(serverurl + "getRealTimeNotification").then(function(response) {
      if (response.data.length > 0) {
        dispatch(liveViewAction.actions.notificationlist(response.data));
        axios.get(serverurl + "setNotificationRead").then(function(response) {
          SetNotificationRead(response.data);
        });
      }
    });
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="quick-panel-tooltip">
            <span className="font-bold">Notifications:</span> View Idle and
            Speed Exceptions and Fleet Notifications
          </Tooltip>
        }
      >
        <div
          className="kt-header__topbar-item kt-header__topbar-item--quick-panel"
          data-toggle="kt-tooltip"
          title="Notifications"
          data-placement="right"
          onClick={testclick}
        >
          <div
            className="kt-header__topbar-icon"
            id="kt_quick_panel_toggler_btn"
          >
            <div className="notify_div">
              <BellIcon
                width="40"
                active={
                  notificationread === 0 ||
                  notificationcount === 0 ||
                  notificationread === 1
                    ? true
                    : false
                }
                animate={
                  notificationcount !== 0 && notificationread !== 1
                    ? true
                    : notificationcount !== 0 && notificationread === 1
                    ? false
                    : false
                }
                color="#0042ff"
              />
              <NotificationBadge
                count={notificationread === 1 ? "0" : notificationcount}
                effect={Effect.SCALE}
                className="notify_count"
              />{" "}
            </div>
          </div>
        </div>
      </OverlayTrigger>
    </>
  );
}

const mapStateToProps = ({ liveView }) => ({
  liveView,
});
export default connect(mapStateToProps, { liveViewAction })(
  NotificationPanelToggler
);
