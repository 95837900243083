export function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  export function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  export function getTomorrow() {
    let today =  new Date();
    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1)
    console.log("tomrrow date : ", tomorrow)
    return tomorrow;
  }