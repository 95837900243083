// Main Router Page for all the main Pages

import React, { Suspense, lazy,useEffect,useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import Builder from "./Builder";
import Liveview from "./Liveview";
import { LayoutSplashScreen } from "../../../infofleet";
import Support from "./Support";
//functional tree
import { useDispatch, useSelector } from "react-redux";
import * as functionaltree from "../../../infofleet/ducks/functionaltree";
import * as quickoptions from "../../../infofleet/ducks/quickoptions";
import AuthCheck from "../../component/AuthCheck"; // AuthCheck is used to check if user has access for particular page or not by functional tree ids
import { fleet_parentId, homeId, manageaccountaccordId, managesettingsId, mrp_parentId, panelsearchId } from "./constant";
import { serverurl } from "../../router/config";
import axios from "axios";

const Dashboardpage = lazy(() => import("./dashboard/DashboardView"));

const Reportpage = lazy(() => import("./report/ReportView"));

const PanelSearch = lazy(() => import("./panelsearch/PanelView"));

const ManageAccount = lazy(() => import("./manageaccount/ManageAccountView"));

const GeofencePage = lazy(() => import("./geofence/GeofenceView"));

const ManageSettings = lazy(() => import("./managesettings/SettingsView"));

const Fleetpage = lazy(() => import("./fleet/FleetView"));

const MRPpage = lazy(() => import("./mrp/MrpView"));

const UnAuthorized = lazy(() => import("../errors/UnAuthorized"))

export default function HomePage() {
  const url = window.location.href;
  const lastSegment = url.split("/").pop();

   if (lastSegment !== "Liveview") {
    const clearinterval_id_LiveView = localStorage.getItem("LiveView_interval");
    clearInterval(clearinterval_id_LiveView);
    localStorage.removeItem("LiveView_interval");
  }

 {/*
  useEffect(() => {


    
   // Define the Tawk.to onLoad callback function
window.Tawk_API.onLoad = function () {
  const userurl = "getLoggedUserprofile";
  
  axios.get(serverurl + userurl).then(function (response) {
    let UserProfile = response.data[0];
    console.log("Inside useeffect of HomePage.js userprofile :", UserProfile);

    window.Tawk_API.setAttributes(
      {
        name: UserProfile.userName,
        email: UserProfile.userEmail,
      },
      function (error) {
        if (!error) {
          // The attributes were set successfully
          console.log("Tawk.to attributes set successfully");
        } else {
          console.error("Error setting Tawk.to attributes:", error);
        }
      }
    );
  });
};

 

   
  }, []);
  */}

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /Liveview. */
          <Redirect exact from="/" to="/Liveview" />
        }
        <Route path="/Liveview" component={Liveview} />
        <Route path="/report" component={Reportpage} /> 
        <Route path="/geofence" component={GeofencePage} />
        <Route path="/dashboard" component={Dashboardpage} />          
        <Route path="/Support" component={Support} />
        

        {AuthCheck(homeId,panelsearchId)         ? <Route path="/panelsearch" component={PanelSearch} /> : <Route path="/panelsearch" component={UnAuthorized} />}
        {AuthCheck(fleet_parentId)               ? <Route path="/fleet" component={Fleetpage} />         : <Route path="/fleet" component={UnAuthorized}/>}         
        {AuthCheck(homeId,manageaccountaccordId) ? <Route path="/manageaccount" component={ManageAccount} /> : <Route path="/manageaccount" component={UnAuthorized} />}   
        {AuthCheck(homeId,managesettingsId)      ? <Route path="/managesettings" component={ManageSettings} />  : <Route path="/managesettings" component={UnAuthorized} />}    
        {AuthCheck(mrp_parentId)                 ? <Route path="/mrp" component={MRPpage}/>        : <Route path="/mrp" component={UnAuthorized}/>}              
        <Redirect to="/error/error-v6" />
      </Switch>
    </Suspense>
  );
}
