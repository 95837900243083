// import { put, takeLatest } from "redux-saga/effects";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../app/router/RouterHelpers";

export const actionTypes = {
  getLiveView: "SET_LIVE_VIEW",
  filterData: "FILTER_DATA",
  selectedVId: "SELECTED_VEHICLE_ID",
  singleassetview: "SINCLE_ASSET_VIEW",
  selectednotification: "ALERT_DATA",
  notificationlist: "NOTIFICATION_LIST",
  Resetdata: "[Logout] Action",
  MovingFilterData: "MOVING_FILTERDATA",
  ParkedFilterData: "PARKED_FILTERDATA",
  NosignalFilterData: "NOSIGNAL_FILTERDATA",
  StoppedFilterData: "STOPPED_FILTERDATA",
  EngineOnFilterData: "ENGINE+ON_FILTERDATA"
};

const initialState = {
  data: [],
  filterData: [],
  selectedVId: [],
  selectednotification: "",
  singleassetview: "hide",
  notificationlist: "",
  MovingFilterData: [],
  ParkedFilterData: [],
  NosignalFilterData: [],
  StoppedFilterData: [],
  EngineOnFilterData: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: "build-demo2",
  },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case actionTypes.getLiveView:
        return { ...state, data: payload };
      case actionTypes.filterData:
        return { ...state, filterData: payload };
        case actionTypes.MovingFilterData:
        return { ...state, MovingFilterData: payload };
      case actionTypes.ParkedFilterData:
        return { ...state, ParkedFilterData: payload };
      case actionTypes.NosignalFilterData:
        return { ...state, NosignalFilterData: payload };
      case actionTypes.StoppedFilterData:
        return { ...state, StoppedFilterData: payload };
      case actionTypes.EngineOnFilterData:
        return { ...state, EngineOnFilterData: payload };
      case actionTypes.selectedVId:
        return { ...state, selectedVId: payload };
      case actionTypes.selectednotification:
        return { ...state, selectednotification: payload };
      case actionTypes.singleassetview:
        return { ...state, singleassetview: payload };
      case actionTypes.notificationlist:
        return { ...state, loading: false, notificationlist: payload };

      case actionTypes.Resetdata: {
        routerHelpers.forgotLastLocation();
        return initialState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getLiveView: (payload) => ({ payload, type: actionTypes.getLiveView }),
  filterData: (payload) => ({ payload, type: actionTypes.filterData }),
  MovingFilterData:  (payload) => ({ payload, type: actionTypes.MovingFilterData }),
  ParkedFilterData :  (payload) => ({ payload, type: actionTypes.ParkedFilterData }),
  NosignalFilterData:  (payload) => ({ payload, type: actionTypes.NosignalFilterData }),
  StoppedFilterData :  (payload) => ({ payload, type: actionTypes.StoppedFilterData }),
  EngineOnFilterData :  (payload) => ({ payload, type: actionTypes.EngineOnFilterData }),
  selectedVId: (payload) => ({ payload, type: actionTypes.selectedVId }),
  singleassetview: (payload) => ({
    payload,
    type: actionTypes.singleassetview,
  }),
  selectednotification: (payload) => ({
    payload,
    type: actionTypes.selectednotification,
  }),
  notificationlist: (payload) => ({
    payload,
    type: actionTypes.notificationlist,
  }),

  Resetdata: () => ({ type: actionTypes.Resetdata }),
};
